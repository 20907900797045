import { Market, MarketsGroupedByMarketType, MarketType, Outcome } from '../@types';
import MarketAccordion from '../components/molecules/MarketAccordion';
import MarketTable from '../components/organisms/tables/MarketTable';
import MarketTableDisplaySingle from '../components/organisms/tables/MarketTableDisplaySingle';
import MarketsTableDisplayMultiple from '../components/organisms/tables/MarketsTableDisplayMultiple';
import { MARKET_TYPE_ASIAN_HANDICAP_WITH_SCORE } from '../constants';

export const getAccordionTitle = (market: Market | MarketsGroupedByMarketType) => {
  if ('markets' in market) {
    const firstMarket = market.markets[0];
    if (firstMarket?.marketType?.name === MARKET_TYPE_ASIAN_HANDICAP_WITH_SCORE) {
      // find a score value in the market name (i.e. [0:1] or [1:0])
      const match = firstMarket?.name.match(/\[(.*?)\]/);
      const name = market?.shortName || market?.name;
      // if name includes a comma, split the name and add the score value in between
      // because second part of the name is the event part name
      if (name.includes(',')) {
        const splitName = name.split(',');
        return `${splitName[0]} ${match ? `(${match[1]})` : ''}, ${splitName[1]}`;
      }
      return (market.shortName || market.name) + (match ? ` (${match[1]})` : '');
    }
    return market.shortName ?? market.name;
  } else if (market.specialValues?.length > 0) {
    return market.shortName ?? `${market.name} - ${market.specialValues[0].model?.name ?? ''}`;
  } else {
    return market.shortName ?? market.name;
  }
};

export const renderMarketAccordion = (
  market: Market | MarketsGroupedByMarketType,
  onCellClick: (market: Market, outcome: Outcome) => void
) => {
  const commonProps = {
    defaultExpanded: !market.isCollapsedMode,
    showFavorites: true,
    isFavoriteMarket: market.favourite,
  };

  if ('markets' in market) {
    return (
      <MarketAccordion
        {...commonProps}
        key={market.id}
        marketId={market.marketType}
        title={getAccordionTitle(market)}
        marketTypeName={market.marketType as unknown as string}
      >
        <MarketsTableDisplayMultiple data={market} handleCellClick={onCellClick} />
      </MarketAccordion>
    );
  } else if (market.specialValues?.length > 0) {
    return (
      <MarketAccordion
        {...commonProps}
        key={market.id}
        marketId={market.marketTypeId}
        title={getAccordionTitle(market)}
        marketTypeName={market.marketType.name as unknown as string}
      >
        <MarketTableDisplaySingle data={market} handleCellClick={onCellClick} />
      </MarketAccordion>
    );
  } else {
    return (
      <MarketAccordion
        {...commonProps}
        key={market.id}
        marketId={market.marketTypeId}
        title={getAccordionTitle(market)}
        marketTypeName={market.marketType.name as unknown as string}
      >
        <MarketTable data={market} handleCellClick={onCellClick} />
      </MarketAccordion>
    );
  }
};

// Example: "sportsbook.dev.neotechsolutions.org" -> ["dev", "neotechsolutions", "org"]
const getHostnameSegments = () => {
  const segments = window.location.hostname.split('.');

  if (segments.length > 2) {
    segments.shift();
  }
  return segments;
};

// Example: service="api", domain="dev.neotechsolutions.org" -> "https://api.dev.neotechsolutions.org"
const buildServiceUrl = (service: string) => {
  const segments = getHostnameSegments();
  return `https://${service}.${segments.join('.')}`;
};

export const getClientId = () => getHostnameSegments().join('.');
export const getApiUrl = (apiEndpoint = '') => `${buildServiceUrl('api')}${apiEndpoint && `/${apiEndpoint}`}`;
export const getAuthUrl = () => buildServiceUrl('auth');

export const getKeycloakUrl = () => process.env.REACT_APP_KEYCLOAK_URL ?? getAuthUrl();
export const getKeycloakClientId = () => process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? getClientId();
export const getBaseApiUrl = () => process.env.REACT_APP_API_BASE_URL ?? getApiUrl();

import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { getData } from '../utils/api';
import { ThemeOptions } from '../styles/themeConfig';

type MarketplaceConfig = {
  feedRunning: boolean;
  id: string;
  sanityConfig: {
    projectId: string;
    dataset: string;
  };
  branding: ThemeOptions;
};

type UseMarketplaceConfigOptions = UseQueryOptions<MarketplaceConfig, Error>;

export const useMarketplaceConfig = (options?: UseMarketplaceConfigOptions) => {
  return useQuery({
    queryKey: [QUERY_KEYS.marketplaceConfig],
    queryFn: (): Promise<MarketplaceConfig> => getData('marketplace/config', undefined, 'crm'),
    ...options,
  });
};

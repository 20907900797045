import { QUERY_KEYS } from '../../constants';
import useLocalization from '../../hooks/useLocalization';
import { usePublishedEvents } from '../../queries';
import Heading from '../atoms/Heading';
import Carousel from '../organisms/Carousel';
import EventCard from './EventCard';

type Props = {
  sportId?: string;
};

const FeaturedEventsCarousel = ({ sportId }: Props) => {
  const { data: featuredEvents } = usePublishedEvents({
    queryKey: [QUERY_KEYS.featuredEvents, sportId],
    queryParams: {
      limit: 100,
      sportId,
      featured: true,
      sort: 'startDate',
    },
    options: {
      enabled: !!sportId,
    },
  });

  const { t } = useLocalization();

  if (!featuredEvents?.items.length) {
    return null;
  }

  return (
    <>
      <Heading>
        <Heading.Title>{t('featuredMatches')}</Heading.Title>
      </Heading>
      <Carousel>
        {featuredEvents.items.map((event) => (
          <EventCard key={event.id} event={event} />
        ))}
      </Carousel>
    </>
  );
};

export default FeaturedEventsCarousel;

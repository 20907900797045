import { Box, InputBase, IconButton, Button, alpha } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { StyleObj } from '../../@types';

const styles: StyleObj = {
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '30%',
    left: '35%',
  },
  search: {
    position: 'relative',
    borderRadius: '20px',
    flexGrow: 1,
    backgroundColor: (theme) => alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: (theme) => alpha(theme.palette.common.black, 0.25),
    },
  },
  searchIconWrapper: {
    padding: (theme) => theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputBase: {
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: '10px 10px 10px 0',
      // vertical padding + font size from searchIcon
      paddingLeft: 'calc(1em + 32px)',
      transition: 'width 300ms',
    },
  },
  closeButton: {
    fontSize: 10,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

interface SearchBarProps {
  handleShowSearch: Dispatch<SetStateAction<boolean>>;
}
const SearchBar = ({ handleShowSearch }: SearchBarProps) => {
  const [searchValue, setSearchValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleDelete = () => {
    setSearchValue('');
  };
  return (
    <Box sx={styles.searchWrapper}>
      <Box sx={styles.search}>
        <Box sx={styles.searchIconWrapper}>
          <SearchIcon />
        </Box>
        <InputBase
          sx={styles.inputBase}
          placeholder='Search…'
          inputProps={{ 'aria-label': 'search' }}
          value={searchValue}
          onChange={handleChange}
        />
        {searchValue && (
          <IconButton
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              color: 'secondary.main',
            }}
            onClick={handleDelete}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <Button sx={styles.closeButton} onClick={() => handleShowSearch(false)}>
        Close
      </Button>
    </Box>
  );
};

export default SearchBar;

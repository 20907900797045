import { TableCell, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isEmpty } from 'lodash-es';
import { Fragment, useMemo, useCallback } from 'react';
import { Market, Outcome, StyleObj } from '../../../@types';
import { sortMarketsBySpecialValues } from '../../../helpers';
import OutcomeTableCell from '../../atoms/OutcomeTableCell';
import { BetDetails } from '../../atoms/BetDetails';
import useLocalization from '../../../hooks/useLocalization';

type MarketsTablePropsDisplayMultiple = {
  data: {
    id: string;
    marketType: string;
    name: string;
    markets: Market[];
  };
  handleCellClick: (market: Market, outcome: Outcome) => void;
};

const styles: StyleObj = {
  tableContainer: (theme) => ({
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.neutral[600]}`,
    boxShadow: 'none',
  }),
  marketName: {
    mb: 2,
  },
  tableHead: {
    backgroundColor: 'neutral.600',
    borderBottom: 'none',
    '& .MuiTableCell-root': {
      p: 1,
    },
  },
  tableRow: {
    display: 'flex',
  },
  tableHeadCell: {
    flex: 1,
    borderBottom: 'none',
  },
  outcomeCell: (theme) => ({
    flex: 1,
    color: 'secondary.main',
    backgroundColor: theme.palette.neutral[800],
    cursor: 'pointer',
    border: `1px solid ${theme.palette.neutral[600]}`,
    borderBottomColor: theme.palette.primary.main,
    p: 2,
    '&:hover': {
      backgroundColor: theme.palette.neutral[600],
    },
  }),
  participantName: (theme) => ({
    backgroundColor: theme.palette.neutral[800],
    borderBottomColor: theme.palette.primary.main,
  }),
  mainMarketOutcomeType: {
    typography: {
      xs: 'body3',
      md: 'body2',
    },
    color: 'neutral.200',
  },
};

const checkIfAnyMarketHasSpecialValues = (markets: Market[]) => {
  return markets.some((market) => market.specialValues);
};

const getCurrentMarketsSpecialValues = (markets: Market[], id: string) => {
  const currentMarket = markets.find((market) => market.id === id);
  return currentMarket?.specialValues;
};

const getParticipantName = (market: Market) => {
  const specialValueModel = market.specialValues?.[0]?.model;
  return specialValueModel?.playerTeamName ?? specialValueModel?.name;
};

const MarketsTableDisplayMultiple = ({ data, handleCellClick }: MarketsTablePropsDisplayMultiple) => {
  const { t } = useLocalization();

  const hasSpecialValueWithPlayerModel = useMemo(
    () => data?.markets?.[0]?.specialValues?.some((specialValue) => specialValue.modelType === 'player'),
    [data.markets]
  );
  const hasSpecialValueModel = useMemo(
    () => !isEmpty(data?.markets?.find((market) => market.specialValues?.[0]?.model)),
    [data.markets]
  );

  const { marketsWithSpecialValueModel } = useMemo(() => {
    const withModel: Market[] = [];
    const withoutModel: Market[] = [];

    data?.markets?.forEach((market) => {
      if (market.specialValues?.[0]?.model) {
        withModel.push(market);
      } else {
        withoutModel.push(market);
      }
    });

    return {
      marketsWithSpecialValueModel: withModel,
      marketsWithoutModel: withoutModel,
    };
  }, [data.markets]);

  const marketsGroupedByParticipant = useMemo(() => {
    if (!hasSpecialValueModel) return {};

    return marketsWithSpecialValueModel.reduce((groups: { [key: string]: Market[] }, market) => {
      const participantName = getParticipantName(market);
      if (participantName) {
        if (!groups[participantName]) {
          groups[participantName] = [];
        }
        groups[participantName].push(market);
      }
      return groups;
    }, {});
  }, [marketsWithSpecialValueModel, hasSpecialValueModel]);

  const hasSpecialValues = useMemo(() => checkIfAnyMarketHasSpecialValues(data.markets), [data.markets]);

  const getColSpan = useCallback(() => {
    let colSpan = 0;
    if (hasSpecialValueWithPlayerModel) {
      colSpan += 1;
    }
    if (hasSpecialValues) {
      colSpan += data.markets[0].marketType.outcomeTypes.length;
    }
    return colSpan;
  }, [hasSpecialValueWithPlayerModel, hasSpecialValues, data.markets]);

  const memoizedGroupedMarkets = useMemo(() => {
    const sortedGroups = { ...marketsGroupedByParticipant };

    Object.keys(sortedGroups).forEach((key) => {
      sortedGroups[key] = [...sortedGroups[key]].sort(sortMarketsBySpecialValues);
    });

    return sortedGroups;
  }, [marketsGroupedByParticipant]);

  const memoizedSortedMarkets = useMemo(() => data.markets.sort(sortMarketsBySpecialValues), [data.markets]);
  const memoizedGroupMarketName = useMemo(() => data.name, [data.name]);

  const renderMarketRow = useCallback(
    (market: Market, groupMarketName: string) => {
      return (
        <TableRow key={market.id} sx={styles.tableRow}>
          {hasSpecialValueWithPlayerModel && (
            <TableCell align='center' sx={styles.outcomeCell}>
              <Typography variant='body2' color='white'>
                {market.specialValues?.find((specialValue) => specialValue.modelType === 'player')?.model?.name}
              </Typography>
            </TableCell>
          )}
          {market.marketType.outcomeTypes.map((outcomeType) => {
            const outcome = market.outcomes.find((o) => o.outcomeType.id === outcomeType.id);
            return (
              <OutcomeTableCell
                key={outcomeType?.id}
                outcome={outcome}
                specialValues={getCurrentMarketsSpecialValues(data.markets, market.id)}
                onClick={() => {
                  if (!outcome) return;
                  handleCellClick({ ...market, name: groupMarketName }, outcome);
                }}
                isMarketSingleDisplayType={market.isSingleDisplayType}
                marketTypeName={market.marketType.name}
              />
            );
          })}
        </TableRow>
      );
    },
    [data.markets, handleCellClick, hasSpecialValueWithPlayerModel]
  );

  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      <MuiTable aria-label='markets table'>
        <TableHead sx={styles.tableHead}>
          <TableRow sx={styles.tableRow}>
            {hasSpecialValueWithPlayerModel && (
              <TableCell align='center' sx={styles.tableHeadCell}>
                {t('player')}
              </TableCell>
            )}

            {data.markets[0].marketType.outcomeTypes.map((outcomeType) => (
              <TableCell align='center' key={outcomeType.id} sx={styles.tableHeadCell}>
                <BetDetails.Outcome
                  outcomeName={outcomeType.name}
                  outcomeShortName={outcomeType.shortName}
                  sx={styles.mainMarketOutcomeType}
                  component='span'
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isEmpty(marketsGroupedByParticipant)
            ? Object.keys(memoizedGroupedMarkets)?.map((participantName) => (
                <Fragment key={participantName}>
                  <TableRow>
                    <TableCell colSpan={getColSpan()} sx={styles.participantName}>
                      <Typography variant='h6'>{participantName}</Typography>
                    </TableCell>
                  </TableRow>
                  {memoizedGroupedMarkets?.[participantName]?.map((market) =>
                    renderMarketRow(market, memoizedGroupMarketName)
                  )}
                </Fragment>
              ))
            : memoizedSortedMarkets?.map((market) => renderMarketRow(market, memoizedGroupMarketName))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default MarketsTableDisplayMultiple;

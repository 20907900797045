import { Link } from 'react-router-dom';
import { SanitySideItem } from '../../@types';
import { getSanityImage } from '../../helpers/sanity';

type CMSSideItemProps = {
  item: SanitySideItem;
};

const CMSSideItem = ({ item }: CMSSideItemProps) => {
  const src = getSanityImage(item?.image);

  return (
    <Link to={item?.callToActionURL ?? '/'}>
      <img
        alt={item.title}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        src={src}
        title={item.title}
      />
    </Link>
  );
};

export default CMSSideItem;

import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Market, Outcome, StyleObj } from '../../../@types';
import { getOutcomeCellWidth, getOutcomesByOutcomeTypeWithLength } from '../../../helpers';
import OutcomeTableCell from '../../atoms/OutcomeTableCell';

type MarketTableProps = {
  data: Market;
  handleCellClick: (market: Market, outcome: Outcome) => void;
};

const makeStyles = (outcomesLength: number): StyleObj => ({
  tableContainer: (theme) => ({
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.neutral[600]}`,
    boxShadow: 'none',
  }),
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > *': {
      minWidth: getOutcomeCellWidth(outcomesLength),
      maxWidth: getOutcomeCellWidth(outcomesLength),
    },
  },
});

const MarketTable = ({ data, handleCellClick }: MarketTableProps) => {
  const { outcomes, marketType, isActive, isSingleDisplayType } = data;
  const { outcomeTypes } = marketType;

  const { outcomesByType, totalOutcomesLength } = getOutcomesByOutcomeTypeWithLength(outcomeTypes, outcomes);

  const styles = makeStyles(totalOutcomesLength);

  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      <MuiTable aria-label='events table'>
        <TableBody>
          <TableRow sx={styles.tableRow}>
            {Object.entries(outcomesByType).map(([outcomeTypeId, outcomesForType]) => {
              return outcomesForType.map((outcome) => (
                <OutcomeTableCell
                  key={outcome ? outcome.id : outcomeTypeId}
                  outcome={outcome}
                  onClick={() => {
                    if (!outcome) return;
                    handleCellClick(data, outcome);
                  }}
                  disabled={!isActive || !outcome?.isActive}
                  isMarketSingleDisplayType={isSingleDisplayType}
                  marketTypeName={marketType?.name}
                />
              ));
            })}
          </TableRow>
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default MarketTable;

import useLocalization from '../../hooks/useLocalization';
import { usePublishedEvents } from '../../queries';
import Heading from '../atoms/Heading';
import Carousel from '../organisms/Carousel';
import EventCard from './EventCard';

const FeaturedTournamentCarousel = () => {
  const { t } = useLocalization();
  const { data: featuredTournamentEvents } = usePublishedEvents({
    queryParams: {
      featuredTournament: true,
      limit: 20,
    },
  });

  if (!featuredTournamentEvents?.items.length) {
    return null;
  }

  const tournament = featuredTournamentEvents.items[0].tournament;

  return (
    <>
      <Heading to={`${tournament.competition.sportId}/tournaments/${tournament.id}`}>
        <Heading.Title>{tournament.parentTournamentName ?? tournament.name}</Heading.Title>

        <Heading.Link>{t('goToMatches')}</Heading.Link>
      </Heading>
      <Carousel>
        {featuredTournamentEvents.items.map((event) => (
          <EventCard key={event.id} event={event} />
        ))}
      </Carousel>
    </>
  );
};

export default FeaturedTournamentCarousel;

import { isEmpty } from 'lodash-es';
import { useAuth } from '@neotech-solutions-org/neofusion-fe-shared';
import { useCallback, useEffect, useRef } from 'react';
import { SECOND, WS_EVENTS_ENUMS } from '../constants';
import { socket } from '../constants/socket';
import Log from '../utils/logger';

export type MessageType<T> = {
  event: (typeof WS_EVENTS_ENUMS)[keyof typeof WS_EVENTS_ENUMS];
  id: string;
  payload: T;
};

export type WebsocketData<T> = Record<string, MessageType<T>>;
type JoinRoom = (roomId: string) => void;
type LeaveRoom = (roomId: string) => void;
type SendMessage = (event: string, data: unknown) => void;

/**
 * The useWebsocket function sets up a WebSocket connection and provides functions for sending messages, joining and leaving rooms, and resetting the WebSocket connection.
 *
 * @param {Object} options - The options for configuring the WebSocket connection.
 * @param {boolean} options.debug - Whether to enable debug logging.
 * @param {function} options.callback - The callback function to be called with the cached messages.
 * @returns {Object} An object containing the following functions:
 *   - sendMessage: A function for sending messages through the WebSocket connection.
 *   - joinRoom: A function for joining a room.
 *   - leaveRoom: A function for leaving a room.
 *   - resetWS: A function for resetting the WebSocket connection.
 */
const useWebsocket = <Raw>({
  debug = false,
  callback,
}: {
  delay?: number;
  debug?: boolean;
  callback: (data: WebsocketData<Raw>[]) => void;
}): {
  sendMessage: SendMessage;
  joinRoom: JoinRoom;
  leaveRoom: LeaveRoom;
  resetWS: () => void;
} => {
  const joinedRooms = useRef<Set<string>>(new Set());
  const bufferQueue = useRef<WebsocketData<Raw>[]>([]);

  const { userId } = useAuth();

  const sendMessage: SendMessage = useCallback((event, data) => socket.emit(event, data), []);

  const joinRoom: JoinRoom = useCallback((roomId) => {
    socket.emit('joinRoom', roomId);

    joinedRooms.current.add(roomId);
  }, []);

  const leaveRoom: LeaveRoom = useCallback((roomId) => {
    socket.emit('leaveRoom', roomId);
  }, []);

  const resetWS = useCallback(() => {
    joinedRooms.current.forEach((roomId) => {
      if (roomId === userId) return;

      leaveRoom(roomId);
    });

    joinedRooms.current = new Set([userId].filter(Boolean));
  }, [leaveRoom, userId]);

  useEffect(() => {
    if (!socket.connected) {
      socket.io.opts.query = {
        userId,
      };
      socket.connect();
    }

    const onMessage = (event: string, message: MessageType<Raw>) => {
      if (!event || isEmpty(message) || !message?.id || !message?.event || !message?.payload) return;

      const { id } = message;
      // if id contains _, first part is roomId
      const roomId = id.split('_')[0] || id;

      bufferQueue.current.push({
        [roomId]: message,
      });
    };

    if (debug) {
      socket.on('connect', () => Log('[WS]: Connected'));
      socket.on('connect_error', (err) => Log('[WS] Connection Error: ', err, 'error'));
      socket.on('disconnect', (reason, details) => Log('[WS]: Disconnected', { reason, details }, 'error'));
    }

    socket.onAny(onMessage);

    return () => {
      socket.offAny(onMessage);
      resetWS();
    };
  }, [debug, resetWS, userId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (bufferQueue.current.length > 0) {
        callback([...bufferQueue.current]);
        bufferQueue.current.length = 0;
      }
    }, 1 * SECOND);

    return () => clearInterval(interval);
  }, [callback]);

  return {
    sendMessage,
    joinRoom,
    leaveRoom,
    resetWS,
  };
};

export default useWebsocket;

import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Market, Outcome, StyleObj } from '../../../@types';
import OutcomeTableCell from '../../atoms/OutcomeTableCell';

type MarketTableDisplaySingleProps = {
  data: Market;
  handleCellClick: (market: Market, outcome: Outcome) => void;
};

const styles: StyleObj = {
  tableContainer: (theme) => ({
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.neutral[600]}`,
    boxShadow: 'none',
  }),
  tableHead: {
    backgroundColor: 'neutral.600',
    borderBottom: 'none',
    '& .MuiTableCell-root': {
      p: 1,
    },
  },
  tableHeadCell: {
    borderBottom: 'none',
    p: 0,
  },
  outcomeCell: (theme) => ({
    color: 'secondary.main',
    backgroundColor: theme.palette.neutral[800],
    cursor: 'pointer',
    border: `1px solid ${theme.palette.neutral[600]}`,
    borderBottomColor: theme.palette.primary.main,
    px: 2,
    py: 4,
    '&:hover': {
      backgroundColor: theme.palette.neutral[600],
    },
  }),
  mainMarketOutcomeType: {
    typography: {
      xs: 'body3',
      md: 'body2',
    },
    color: 'neutral.200',
  },
};

const MarketTableDisplaySingle = ({ data, handleCellClick }: MarketTableDisplaySingleProps) => {
  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      <MuiTable aria-label='events table'>
        <TableHead sx={styles.tableHead}>
          <TableRow>
            {data.marketType.outcomeTypes.map((outcomeTypes) => {
              const outcome = data.outcomes.find((o) => o.outcomeTypeId === outcomeTypes.id);
              return (
                <TableCell align='center' key={outcome?.id} sx={styles.tableHeadCell}>
                  <Typography sx={styles.mainMarketOutcomeType} component='span' key={outcome?.id}>
                    {outcome?.shortName || outcome?.name}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {data.marketType.outcomeTypes.map((outcomeType) => {
              const outcome = data.outcomes.find((o) => o.outcomeTypeId === outcomeType.id);
              return (
                <OutcomeTableCell
                  key={outcomeType?.id}
                  outcome={outcome}
                  specialValues={data.specialValues}
                  onClick={() => {
                    if (outcome) {
                      handleCellClick(data, outcome);
                    }
                  }}
                  disabled={!data.isActive || !outcome?.isActive}
                  isMarketSingleDisplayType={data.isSingleDisplayType}
                  marketTypeName={data.marketType?.name}
                />
              );
            })}
          </TableRow>
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default MarketTableDisplaySingle;

import { Box, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SanityImage, StyleObj } from '../../@types';
import CMSTopContent from '../organisms/CMSTopContent';
import { useSanity } from '../../hooks/useSanity';
import { LoaderContainer } from '../atoms/LoaderContainer';
import { useIFrameData } from '../../hooks/useIFrameData';
import { useUserSettings } from '../../queries';
import { useLocation } from 'react-router-dom';
import { getSanityImage } from '../../helpers/sanity';
import { useThemeContext } from '../../contexts/ThemeContext';
import { ThemeOptions } from '../../styles/themeConfig';

const styles: StyleObj = {
  container: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    '@media (max-width: 900px)': {
      overflow: 'hidden',
    },
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1400px',
    height: '1200px',
    '@media (max-width: 900px)': {
      height: '100%',
    },
  },
};
const CasinoPage = () => {
  const { data: backgroundImage } = useSanity<{ image: SanityImage }>('CasinoBackgroundImage');
  const theme = useTheme();
  const [casinoUrl, setCasinoUrl] = useState<string>('');
  const location = useLocation();
  const [ready, setReady] = useState(false);
  const { url } = useIFrameData();

  const { selectedTheme } = useThemeContext();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const gameId = searchParams.get('gameId');
    if (gameId) {
      setCasinoUrl(`${url}#/game-page/${gameId}?timestamp=${new Date().getTime()}`);
    } else {
      setCasinoUrl(url);
    }
  }, [location.pathname, location.search, url, location.key]);

  const src = getSanityImage(backgroundImage?.[0]?.image);

  const { data: userData } = useUserSettings();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const sendMessageToIframe = useCallback(
    (message: { language: string; selectedTheme?: ThemeOptions }) => {
      if (iframeRef.current?.contentWindow) {
        iframeRef.current.contentWindow.postMessage(message, url);
      }
    },
    [url]
  );

  const handleIframeLoad = () => {
    setReady(true);
    sendMessageToIframe({ language: userData?.language ?? 'en', selectedTheme });
  };

  useEffect(() => {
    if (url) sendMessageToIframe({ language: userData?.language ?? 'en' });
  }, [sendMessageToIframe, url, userData?.language]);

  return (
    <Box
      height={1}
      sx={{
        ...styles.container,
        backgroundImage: `url("${src}")`,
      }}
    >
      <Box sx={styles.innerWrapper}>
        <Box>
          <CMSTopContent scope='casino' />
        </Box>
        <Box
          width={1}
          sx={{
            flex: 1,
            backgroundColor: 'neutral.700',
          }}
        >
          {!ready && (
            <Box sx={{ height: '100%' }}>
              <LoaderContainer />
            </Box>
          )}
          <iframe
            ref={iframeRef}
            title='casino'
            src={casinoUrl}
            onLoad={handleIframeLoad}
            style={{
              border: 'none',
              width: '100%',
              height: '100%',
              backgroundColor: theme.palette.background.default,
            }}
            allowFullScreen
          ></iframe>
        </Box>
      </Box>
    </Box>
  );
};

export default CasinoPage;

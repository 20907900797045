import { createClient, SanityClient } from '@sanity/client';
import dayjs from 'dayjs';
import imageUrlBuilder from '@sanity/image-url';
import { SanityImage } from '../@types';

let sanityClient: SanityClient | null = null;

export const initSanityClient = (projectId?: string, dataset?: string) => {
  const finalProjectId = process.env.REACT_APP_SANITY_PROJECT_ID || projectId;
  const finalDataset = process.env.REACT_APP_SANITY_DATASET || dataset;

  if (!finalProjectId || !finalDataset) {
    return null;
  }

  if (!sanityClient) {
    sanityClient = createClient({
      projectId: finalProjectId,
      dataset: finalDataset,
      useCdn: true,
      apiVersion: dayjs().format('YYYY-MM-DD'),
    });
  }

  return sanityClient;
};

export const getSanityImage = (image?: SanityImage) => {
  if (!image?.asset?._ref || !sanityClient) {
    return '';
  }
  const builder = imageUrlBuilder(sanityClient);
  const img = builder.image(image.asset._ref);
  return img?.url();
};

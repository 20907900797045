import axios, { InternalAxiosRequestConfig, AxiosInstance } from 'axios';
import { getAccessToken } from '@neotech-solutions-org/neofusion-fe-shared';
import { getApiUrl } from './urlUtils';

export enum ApiServiceEndpoint {
  FEED = 'sportsbook-feed-api',
  WALLET = 'sb-wallet-api',
  CRM = 'sportsbook-crm-api',
  CASINO = 'casino-api',
}

export const createHttpInstance = (service: ApiServiceEndpoint): AxiosInstance => {
  const envUrlMap = {
    [ApiServiceEndpoint.FEED]: process.env.REACT_APP_API_FEED_URL,
    [ApiServiceEndpoint.WALLET]: process.env.REACT_APP_API_WALLET_URL,
    [ApiServiceEndpoint.CRM]: process.env.REACT_APP_API_CRM_URL,
    [ApiServiceEndpoint.CASINO]: process.env.REACT_APP_API_CASINO_URL,
  };

  return axios.create({
    baseURL: envUrlMap[service] ?? getApiUrl(service),
  });
};

const http = createHttpInstance(ApiServiceEndpoint.FEED);
const httpWallet = createHttpInstance(ApiServiceEndpoint.WALLET);
const httpCrm = createHttpInstance(ApiServiceEndpoint.CRM);
export const httpCasino = createHttpInstance(ApiServiceEndpoint.CASINO);

export const createRequestInterceptor = (token?: string) => {
  return async (config: InternalAxiosRequestConfig) => {
    const finalToken = token || (await getAccessToken());

    if (finalToken) {
      config.headers.Authorization = `Bearer ${finalToken}`;
    }

    return config;
  };
};

http.interceptors.request.use(createRequestInterceptor());
httpWallet.interceptors.request.use(createRequestInterceptor());
httpCrm.interceptors.request.use(createRequestInterceptor());

const axiosInstances = {
  feed: http,
  wallet: httpWallet,
  crm: httpCrm,
  casino: httpCasino,
} as const;

export type ApiInstances = typeof axiosInstances;

const executeRequest = async (
  method: 'get' | 'post' | 'patch' | 'delete',
  path: string,
  body: unknown | null,
  apiService: keyof ApiInstances = 'feed',
  queryParams?: unknown,
  headers?: Record<string, string | null>
) => {
  if (method === 'get') {
    const { data } = await axiosInstances[apiService].get(path, { params: queryParams });
    return data;
  } else if (method === 'delete') {
    const { data } = await axiosInstances[apiService].delete(path, { data: body });
    return data;
  } else {
    const { data } = await axiosInstances[apiService][method](path, body);
    return data;
  }
};

export const getData = async (
  url: string,
  queryParams?: unknown,
  apiService: keyof ApiInstances = 'feed',
  headers?: Record<string, string | null>
) => {
  return executeRequest('get', url, null, apiService, queryParams, headers);
};

export const postData = async (url: string, body: unknown, apiService: keyof ApiInstances = 'feed') => {
  return executeRequest('post', url, body, apiService);
};

export const patchData = async (url: string, body: unknown, apiService: keyof ApiInstances = 'feed') => {
  return executeRequest('patch', url, body, apiService);
};

export const deleteData = async (url: string, body: unknown | null, apiService: keyof ApiInstances = 'feed') => {
  return executeRequest('delete', url, body, apiService);
};

export const hasApiMorePages = (count: number, page: number, limit: number) => {
  const pagesCount = Math.ceil(count / limit);
  return page < pagesCount;
};

export const fetchCasinoAccessToken = async () => {
  try {
    const token = await getAccessToken();
    const response = await postData('/auth/authenticate', { token }, 'casino');
    return response?.accessToken;
  } catch (error) {
    console.error(error);
  }
};

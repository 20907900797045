import { useEffect, useState } from 'react';
import { useCountPendingBetslips } from '../queries';

type UsePendingBetslipTrackerProps = {
  onAllBetslipsResolved: () => void;
};

export const usePendingBetslipTracker = ({ onAllBetslipsResolved }: UsePendingBetslipTrackerProps) => {
  const { data: pendingBetslipsCount, isSuccess: pendingBetslipsCountSuccess } = useCountPendingBetslips();
  const [previousPendingCount, setPreviousPendingCount] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!pendingBetslipsCountSuccess) {
      return;
    }

    const currentPendingCount = pendingBetslipsCount?.count;
    const hasPreviousCount = typeof previousPendingCount === 'number';

    const hasResolvedAllPendingBetslips = currentPendingCount === 0 && hasPreviousCount && previousPendingCount > 0;

    if (hasResolvedAllPendingBetslips) {
      onAllBetslipsResolved();
    }

    setPreviousPendingCount(currentPendingCount);
  }, [pendingBetslipsCountSuccess, pendingBetslipsCount?.count, onAllBetslipsResolved, previousPendingCount]);

  return {
    hasPendingBetslips: !!pendingBetslipsCount?.count,
  };
};

import CMSSideItem from '../molecules/CMSSideItem';
import { Box, BoxProps } from '@mui/material';
import { SanitySideItem } from '../../@types';
import { useSanity } from '../../hooks/useSanity';

const CMSSideContent = ({ sx }: BoxProps) => {
  const { data: cmsContent } = useSanity<SanitySideItem>('PromotionalSideContent');

  return (
    <Box
      sx={{
        height: '100vh',
        position: 'relative',
        display: 'flex',
        overflowY: 'scroll',
        flexDirection: 'column',
        ...sx,
      }}
    >
      {cmsContent?.map((item) => <CMSSideItem key={item.title} item={item} />)}
    </Box>
  );
};

export default CMSSideContent;

import { Container, CssBaseline } from '@mui/material';
import Box from '@mui/material/Box';
import { PropsWithChildren, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useBetslip } from '../../contexts/BetslipContext';
import { getSidebarType } from '../../helpers';
import useBreakpoints from '../../hooks/useBreakpoints';
import useWinningJackpot from '../../hooks/useWinningJackpot';
import DrawerNavigation from '../molecules/DrawerNavigation';
import Header from '../molecules/Header';
import JackpotAnimation from '../molecules/JackpotAnimation';
import BetslipDrawer from '../organisms/BetslipDrawer';
import CMSSideContent from '../organisms/CMSSideContent';
import ReofferedBetslipDrawer from '../organisms/ReofferedBetslipDrawer';

const styles = {
  container: { display: 'flex', width: '100%' },
  main: {
    height: '100vh',
    flexGrow: 1,
    overflowX: 'hidden',
    '@supports (height: 100dvh)': {
      height: '100dvh',
    },
  },
};

const MainLayout = ({ children }: PropsWithChildren) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { isMobile, isTablet } = useBreakpoints();
  const { bets } = useBetslip();
  const makeSpaceForBetSlip = bets.length > 0 && isMobile;

  const { clearJackpot, winningJackpot } = useWinningJackpot();

  const location = useLocation();
  const sidebarType = getSidebarType(location);

  const toggleDrawerVisibility = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen);
  };

  const isCasinoRoute = location.pathname === '/casino';
  const fullWidth = isCasinoRoute;

  return (
    <Container
      disableGutters
      sx={{
        ...styles.container,
        maxWidth: fullWidth ? '100vw !important' : '1900px !important',
        mx: fullWidth ? 0 : 'auto',
      }}
    >
      <CssBaseline />
      <Header toggleDrawerVisibility={toggleDrawerVisibility} />
      {sidebarType && (
        <DrawerNavigation isOpen={isDrawerOpen} toggleVisibility={toggleDrawerVisibility} sidebarType={sidebarType} />
      )}
      {!!winningJackpot && <JackpotAnimation winningJackpotPool={winningJackpot} clearJackpot={clearJackpot} />}
      <Box
        width={1}
        component='main'
        sx={{
          ...styles.main,
          px: isMobile || fullWidth ? 0 : 2,
          pt: 8,
          pb: makeSpaceForBetSlip ? 4.5 : 0,
        }}
      >
        {children}
      </Box>
      {location.pathname === '/sports' && !isTablet && <CMSSideContent sx={{ flex: '0 0 270px', pt: 8 }} />}
      <BetslipDrawer />
      <ReofferedBetslipDrawer />
    </Container>
  );
};

export default MainLayout;

import { Typography } from '@mui/material';
import { useSanity } from '../../hooks/useSanity';
import { SanityImage } from '../../@types';
import { useEffect } from 'react';
import { getSanityImage } from '../../helpers/sanity';

type Props = {
  size?: 'small' | 'medium';
};

const setFavicon = (url: string) => {
  let link = document.getElementById('dynamic-favicon') as HTMLLinkElement;
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }
  link.href = url;
};

const Logo = ({ size = 'medium' }: Props) => {
  const { data: logo } = useSanity<{ image: SanityImage }>('Logo');
  const sanitySrc = getSanityImage(logo?.[0]?.image);
  const storedSrc = localStorage.getItem('logoSrc') ?? '';

  const src = sanitySrc || storedSrc;

  useEffect(() => {
    setFavicon(src);
    if (sanitySrc) {
      localStorage.setItem('logoSrc', src);
    }
  }, [sanitySrc, src]);

  if (!src) {
    return (
      <Typography variant={size === 'small' ? 'h3' : 'h1'} color='secondary'>
        LOGO
      </Typography>
    );
  }

  return <img src={src} height={size === 'medium' ? '54px' : '32px'} alt='Logo' />;
};

export default Logo;

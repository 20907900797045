import { ExpandMore } from '@mui/icons-material';
import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion, Typography } from '@mui/material';
import { useState } from 'react';
import { StyleObj } from '../../@types';
import FavoritesCheckbox from '../atoms/FavoritesCheckbox';
import useBreakpoints from '../../hooks/useBreakpoints';
import { BetDetails } from '../atoms/BetDetails';

type AccordionProps = {
  title: string;
  children: React.ReactNode;
  defaultExpanded?: boolean;
  marketId?: string;
  isFavoriteMarket?: boolean;
  showFavorites?: boolean;
  marketTypeName?: string;
};

const styles: StyleObj = {
  accordion: {
    backgroundColor: 'primary.600',
    boxShadow: 'none',
    borderRadius: 0,
    '&.Mui-expanded': {
      my: 0,
      '&:before': {
        opacity: 1,
      },
    },
    ':before': {
      backgroundColor: 'neutral.600',
    },
    '&.MuiAccordion-root': {
      borderRadius: 0,
    },
  },
  accordionSummary: {
    minHeight: 0,
    '&.Mui-expanded': {
      minHeight: 0,
    },
    '&:hover': {
      '& .MuiAccordionSummary-content h3': {
        color: 'info.main',
      },
    },
    '& .MuiAccordionSummary-content': {
      my: {
        xs: 1.5,
        lg: 2,
      },
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      my: {
        xs: 1.5,
        lg: 2,
      },
    },
  },
  accordionDetails: {
    p: 0,
    '& .MuiPaper-root': {
      borderRadius: 0,
    },
  },
  expandMoreIcon: {
    color: 'neutral.50',
  },
  marketName: {
    color: 'neutral.50',
    typography: {
      xs: 'h5',
      md: 'h4',
    },
  },
  checkbox: {
    ml: 1,
    p: 0,
  },
  uncheckedIcon: { color: 'neutral.400' },
};

const MarketAccordion = ({
  title,
  children,
  defaultExpanded,
  marketId,
  isFavoriteMarket,
  showFavorites,
  marketTypeName,
}: AccordionProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const { isMobile } = useBreakpoints();
  return (
    <MuiAccordion sx={styles.accordion} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore onClick={() => setExpanded(!expanded)} sx={styles.expandMoreIcon} />}
        aria-controls='markets-content'
        id='markets-header'
        sx={styles.accordionSummary}
      >
        <BetDetails.Market marketName={title} marketType={marketTypeName} sx={styles.marketName} />
        {showFavorites && marketId && (
          <FavoritesCheckbox
            id={marketId}
            isChecked={!!isFavoriteMarket}
            entityType='market-types'
            uncheckedColor='neutral.50'
            variant={isMobile ? 'large' : 'xl'}
            sx={styles.checkbox}
          />
        )}
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetails}>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

export default MarketAccordion;

import { Stack, StackProps } from '@mui/material';
import { StyleObj } from '../../@types';
import { useJackpotPools } from '../../queries';
import JackpotIcon from '../icons/JackpotIcon';
import JackpotInfo from './JackpotInfo';
import { merge } from 'lodash-es';

const styles: StyleObj = {
  listContainer: {
    width: '100%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'start',
    overflowX: 'auto',
    p: 0.5,
    py: {
      xs: 0.5,
      md: 1,
    },
    gap: {
      xs: 0.5,
      md: 1,
    },
  },
};

type Props = StackProps;

const JackpotList = ({ sx, ...props }: Props) => {
  const { data: jackpotData } = useJackpotPools();

  return (
    <Stack sx={merge(styles.listContainer, sx)} {...props}>
      {jackpotData?.map((jackpotPool) => {
        return (
          <JackpotInfo
            jackpotPool={jackpotPool}
            key={jackpotPool.id}
            icon={<JackpotIcon type={jackpotPool.icon} size='small' />}
          />
        );
      })}
    </Stack>
  );
};

export default JackpotList;

import { useQuery } from '@tanstack/react-query';
import { initSanityClient } from '../helpers/sanity';
import { useMarketplaceConfig } from '../queries/marketplace';

export type Content =
  | 'PromotionalTopContent'
  | 'PromotionalSideContent'
  | 'PortableTextContent'
  | 'CasinoPromotionalTopContent'
  | 'CasinoBackgroundImage'
  | 'Logo';

const QUERY_KEY_RECORD: Record<Content, string> = {
  PromotionalTopContent: 'top-data',
  PromotionalSideContent: 'side-data',
  PortableTextContent: 'footer-data',
  CasinoPromotionalTopContent: 'top-data-casino',
  CasinoBackgroundImage: 'bg-image-casino',
  Logo: 'logo',
};

const useSanityClient = () => {
  const { data: marketplaceConfig } = useMarketplaceConfig();

  return initSanityClient(marketplaceConfig?.sanityConfig?.projectId, marketplaceConfig?.sanityConfig?.dataset);
};

export const useSanity = <T>(contentType: Content, pathname?: string) => {
  const client = useSanityClient();
  const type = `_type=="${contentType}"`;
  const path = pathname ? `&& path == "${pathname?.split('/')[2]}"` : '';

  return useQuery<T[]>({
    queryKey: [QUERY_KEY_RECORD[contentType], pathname || ''],
    queryFn: () => client?.fetch(`*[${type}${path}]`) ?? Promise.resolve([]),
  });
};

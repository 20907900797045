import { Typography, TypographyProps } from '@mui/material';
import type { Market, SpecialValue, SpecialValueModel } from '../../@types';
import { extractSpecialValuesFromOutcomeName, getSpecialValuesDisplayValue } from '../../helpers';
import useLocalization from '../../hooks/useLocalization';
import { getAccordionTitle } from '../../helpers/renderMarketAccordion';

type SpecialValuesDisplayProps = TypographyProps & {
  outcomeName: string;
  specialValues?: SpecialValue[];
  marketTypeName?: string;
  specialValueModel?: SpecialValueModel | null;
  wrapInParenthesis?: boolean;
};

const SpecialValues = ({
  outcomeName,
  specialValues,
  marketTypeName,
  specialValueModel,
  wrapInParenthesis = true,
  ...rest
}: SpecialValuesDisplayProps) => {
  const { t } = useLocalization();

  const isHandicapMarket = marketTypeName?.toLowerCase().includes('handicap');

  const specialValuesFromOutcomeName = isHandicapMarket
    ? extractSpecialValuesFromOutcomeName(outcomeName, specialValues, isHandicapMarket, marketTypeName)
    : null;

  const displayValue = getSpecialValuesDisplayValue(specialValuesFromOutcomeName, specialValues);

  const formattedSpecialValues = displayValue && (wrapInParenthesis ? ` (${displayValue})` : ` ${displayValue}`);

  const localizedFormattedSpecialValues =
    formattedSpecialValues && isHandicapMarket
      ? formattedSpecialValues.replace(/(\(W\))|(\bW\b)/g, t('w', { ns: 'outcomes' }))
      : formattedSpecialValues;

  return (
    <Typography component='span' variant='inherit' noWrap {...rest}>
      {specialValueModel?.modelType === 'player' ? ` (${specialValueModel.playerTeamName}) ` : ''}
      {localizedFormattedSpecialValues}
    </Typography>
  );
};

type OutcomeProps = TypographyProps & {
  outcomeName: string;
  outcomeShortName?: string;
};

const Outcome = ({ outcomeName, outcomeShortName, ...rest }: OutcomeProps) => {
  const { getLocalizedOutcomeName } = useLocalization();

  return (
    <Typography variant='inherit' component='span' {...rest}>
      {outcomeShortName ?? getLocalizedOutcomeName(outcomeName)}
    </Typography>
  );
};

type MarketProps = TypographyProps & {
  market?: Market;
  marketName?: string;
  marketType?: string;
  marketShortName?: string;
};

const Market = ({ market, marketName, marketType, marketShortName, ...rest }: MarketProps) => {
  const { getLocalizedMarketName } = useLocalization();
  const { marketType: marketTypeData, shortName: marketShort } = market ?? {};
  const shortName = marketShortName ?? marketShort;
  if (shortName) {
    return (
      <Typography variant='body2' component='span' {...rest}>
        {shortName}
      </Typography>
    );
  }

  const { name: marketTypeName, eventPartName } = marketTypeData ?? {};
  let marketDisplayName = '';
  if (marketTypeName?.toLowerCase().includes('handicap')) {
    marketDisplayName = `${marketTypeName}, ${eventPartName}`;
  } else {
    marketDisplayName = market ? getAccordionTitle(market) : marketName ?? '';
  }
  const localizedName = getLocalizedMarketName(marketDisplayName, marketTypeName ?? marketType);

  return (
    <Typography variant='body2' component='span' {...rest}>
      {localizedName}
    </Typography>
  );
};

export const BetDetails = {
  Outcome,
  SpecialValues,
  Market,
};

import { useTranslation } from 'react-i18next';
import i18next, { TOptions } from 'i18next';
import { useCallback } from 'react';
import { outcomeRegexArray } from '../localization/constants';
import {
  DEFAULT_LANGUAGE,
  outcomeCache,
  getMarketCachedValue,
  DYNAMIC_TRANSLATIONS_CONFIG,
  setMarketCachedValue,
} from '../localization/utils';

export type LabelledObject = {
  label: string;
  [key: string]: unknown;
};

const useLocalization = () => {
  const { t, ...useTranslationContext } = useTranslation();

  const getLocalizedConfig = useCallback(
    <T extends LabelledObject>(config: T[], options?: TOptions) => {
      return config.map((tab) => ({
        ...tab,
        label: t(tab.label, options) ?? '',
      }));
    },
    [t]
  );

  const getLocalizedSportName = useCallback(
    (sportName?: string) => {
      return sportName ? t(sportName, { ns: 'sports' }) : '';
    },
    [t]
  );

  const getLocalizedOutcomeName = useCallback(
    (outcome: string) => {
      const language = i18next.language;
      const shouldLocalize = language !== DEFAULT_LANGUAGE;
      if (/^\d+-\d+$|^\d+(\.\d+)?$/.test(outcome) || !shouldLocalize) {
        return outcome;
      }
      const cacheKey = `${outcome}-${language}`;

      const cachedOutcomeValue = outcomeCache.get(cacheKey);

      if (cachedOutcomeValue !== undefined) {
        return cachedOutcomeValue;
      }

      let processed = outcome;

      outcomeRegexArray.forEach(({ rule, key }) => {
        processed = processed.replace(rule, t(key, key, { ns: 'outcomes' }));
      });

      outcomeCache.set(cacheKey, processed);

      return processed;
    },
    [t]
  );

  const getLocalizedMarketName = useCallback(
    (marketName: string, marketType?: string) => {
      const language = i18next.language;
      const shouldLocalize = language !== DEFAULT_LANGUAGE;
      if (!shouldLocalize) {
        return marketName;
      }
      const selectedLanguage = language ?? DEFAULT_LANGUAGE;
      const cashedMarketName = getMarketCachedValue(selectedLanguage, marketName);
      if (cashedMarketName) {
        return cashedMarketName;
      }
      const initialTranslation = t(marketName, { ns: 'markets', defaultValue: null });
      if (initialTranslation) {
        return initialTranslation;
      }

      for (const [key, value] of Object.entries(DYNAMIC_TRANSLATIONS_CONFIG)) {
        if (marketType?.split(/[\s\W]+/).includes(key)) {
          const { patterns, params } = value;
          const rule = patterns.find(({ pattern }) => pattern.test(marketName));
          if (rule) {
            const match = rule.pattern.exec(marketName) ?? [];
            const additionalParams =
              match.length > 2 || key !== 'Interval'
                ? params.reduce((acc: { [key: string]: string }, param, index) => {
                    acc[param] = match[index + 1];
                    return acc;
                  }, {})
                : { [params[1]]: match[1] };
            const translation = t(rule.key, marketName, { ns: 'markets', ...additionalParams });
            setMarketCachedValue(selectedLanguage, marketName, translation);
            return translation;
          }
        }
      }
      return marketName;
    },
    [t]
  );

  const getLocalizedEventPart = useCallback(
    (eventPartName: string) => {
      return t(eventPartName, eventPartName, { ns: 'eventParts' });
    },
    [t]
  );

  return {
    t,
    getLocalizedConfig,
    getLocalizedSportName,
    getLocalizedOutcomeName,
    getLocalizedMarketName,
    getLocalizedEventPart,
    ...useTranslationContext,
  };
};

export default useLocalization;

import { SvgIcon, SvgIconProps } from '@mui/material';
import { SportsType } from '../../@types';
import { useThemeContext } from '../../contexts/ThemeContext';
import { themes } from '../../styles/themeConfig';

type SportsIconProps = {
  name: SportsType;
  size?: number | string;
  sx?: SvgIconProps['sx'];
};

const SportsIcon = ({ name, size, sx }: SportsIconProps) => {
  const { selectedTheme } = useThemeContext();

  const IconComponent = themes[selectedTheme].sportsIcons.assets[name];

  if (!IconComponent) {
    return null;
  }

  return (
    <SvgIcon
      component={IconComponent}
      color='secondary'
      viewBox={themes[selectedTheme].sportsIcons.viewBox}
      sx={{ width: size || 'initial', height: size || 'initial', ...sx }}
    />
  );
};

export default SportsIcon;

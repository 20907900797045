export const playerPatterns = [
  {
    pattern: /^(.*?)\bDouble Double\b/,
    key: 'doubleDouble',
  },
  {
    pattern: /^(.*?)\bTriple Double\b/,
    key: 'tripleDouble',
  },
];

export const teamPatterns = [
  {
    pattern: /^(.*?)\bTotal Score \(More Than\)\b/gi,
    key: 'teamTotalScoreMoreThan',
  },
  {
    pattern: /^(.*?)\bTotal Score \(Pairs\)\b/gi,
    key: 'teamTotalScorePairs',
  },
  {
    pattern: /^(.+?) Number of Corners \(Pairs\), Ordinary Time$/,
    key: 'teamNumberOfCornersPairsOrdinaryTime',
  },
  {
    pattern: /^(.+?) Number of Corners \(Singles\), Ordinary Time$/,
    key: 'teamNumberOfCornersSinglesOrdinaryTime',
  },
  {
    pattern: /^(.+?) To Score, Ordinary Time$/,
    key: 'teamToScoreOrdinaryTime',
  },
  {
    pattern: /^(.+?) To Score, 1st Half \(Ordinary Time\)$/,
    key: 'teamToScore1stHalfOrdinaryTime',
  },
  {
    pattern: /^(.+?) To Score, 2nd Half \(Ordinary Time\)$/,
    key: 'teamToScore2ndHalfOrdinaryTime',
  },
  {
    pattern: /^(.+?) Half With Most Goals$/,
    key: 'teamHalfWithMostGoals',
  },
  {
    pattern: /^(.+?) Number of Corners \(More Than\), Ordinary Time$/,
    key: 'teamNumberOfCornersMoreThanOrdinaryTime',
  },
  {
    pattern: /^First (.+?) Goalscorer, Ordinary Time$/,
    key: 'firstTeamGoalscorerOrdinaryTime',
  },
  {
    pattern: /^(.+?) To Win To Nil, 1st Half \(Ordinary Time\)$/,
    key: 'teamToWinToNil1stHalfOrdinaryTime',
  },
  {
    pattern: /^(.+?) To Win To Nil, 1st Half \(Overtime\)$/,
    key: 'teamToWinToNil1stHalfOvertime',
  },
  {
    pattern: /^(.+?) To Win To Nil, 2nd Half \(Ordinary Time\)$/,
    key: 'teamToWinToNil2ndHalfOrdinaryTime',
  },
  {
    pattern: /^(.+?) To Win To Nil, 2nd Half \(Overtime\)$/,
    key: 'teamToWinToNil2ndHalfOvertime',
  },
  {
    pattern: /^(.+?) To Win To Nil, Ordinary Time$/,
    key: 'teamToWinToNilOrdinaryTime',
  },
  {
    pattern: /^(.+?) To Win To Nil, Overtime Excluding Penalty Round$/,
    key: 'teamToWinToNilOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /^(.*?) Goal (\d+|X) Before Minute (\d{2}:\d{2}), Ordinary Time$/,
    key: 'teamGoalXBeforeMinuteYOrdinaryTime',
  },
  {
    pattern: /^(.*?) Total Score \(Pairs\), Whole Match$/,
    key: 'teamTotalScorePairsWholeMatch',
  },
  {
    pattern: /^(.*?) Total Score \(More Than\), Whole Match$/,
    key: 'teamTotalScoreMoreThanWholeMatch',
  },
];

export const goalXPatterns = [
  {
    pattern: /Team To Score Goal (\d+|X) With Money Back On No Goal, 1st Half \(Ordinary Time\)/,
    key: 'teamToScoreGoalXWithMoneyBackOnNoGoal1stHalfOrdinaryTime',
  },
  {
    pattern: /Team To Score Goal (\d+|X) With Money Back On No Goal, 2nd Half \(Ordinary Time\)/,
    key: 'teamToScoreGoalXWithMoneyBackOnNoGoal2ndHalfOrdinaryTime',
  },
  {
    pattern: /Team To Score Goal (\d+|X) With Money Back On No Goal, Ordinary Time/,
    key: 'teamToScoreGoalXWithMoneyBackOnNoGoalOrdinaryTime',
  },

  {
    pattern: /Team To Score Goal (\d+|X) With Money Back On No Goal, Overtime/,
    key: 'teamToScoreGoalXWithMoneyBackOnNoGoalOvertime',
  },

  {
    pattern: /Team To Score Goal (\d+|X) With Money Back On No Goal, Overtime Excluding Penalty Round/,
    key: 'teamToScoreGoalXWithMoneyBackOnNoGoalOvertimeExcludingPenaltyRound',
  },

  {
    pattern: /Team To Score Goal (\d+|X), 1st Half \(Ordinary Time\)/,
    key: 'teamToScoreGoalX1stHalfOrdinaryTime',
  },
  {
    pattern: /Team To Score Goal (\d+|X), 1st Half \(Overtime\)/,
    key: 'teamToScoreGoalX1stHalfOvertime',
  },
  {
    pattern: /Team To Score Goal (\d+|X), 2nd Half \(Ordinary Time\)/,
    key: 'teamToScoreGoalX2ndHalfOrdinaryTime',
  },
  {
    pattern: /Team To Score Goal (\d+|X), 2nd Half \(Overtime\)/,
    key: 'teamToScoreGoalX2ndHalfOvertime',
  },

  {
    pattern: /Team To Score Goal (\d+|X), Ordinary Time/,
    key: 'teamToScoreGoalXOrdinaryTime',
  },
  {
    pattern: /Team To Score Goal (\d+|X), Overtime/,
    key: 'teamToScoreGoalXOvertime',
  },
  {
    pattern: /Team To Score Goal (\d+|X), Overtime Excluding Penalty Round/,
    key: 'teamToScoreGoalXOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /Team To Score Goal (\d+|X), Whole Match/,
    key: 'teamToScoreGoalXWholeMatch',
  },
  {
    pattern: /Player To Score Goal (\d+|X), 1st Half \(Ordinary Time\)/,
    key: 'playerToScoreGoalX1stHalfOrdinaryTime',
  },
  {
    pattern: /Player To Score Goal (\d+|X), 1st Half \(Overtime\)/,
    key: 'playerToScoreGoalX1stHalfOvertime',
  },
  {
    pattern: /Player To Score Goal (\d+|X), 2nd Half \(Ordinary Time\)/,
    key: 'playerToScoreGoalX2ndHalfOrdinaryTime',
  },
  {
    pattern: /Player To Score Goal (\d+|X), 2nd Half \(Overtime\)/,
    key: 'playerToScoreGoalX2ndHalfOvertime',
  },
  {
    pattern: /Player To Score Goal (\d+|X), Ordinary Time/,
    key: 'playerToScoreGoalXOrdinaryTime',
  },
  {
    pattern: /Player To Score Goal (\d+|X), Overtime Excluding Penalty Round/,
    key: 'playerToScoreGoalXOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /Player To Score Goal (\d+|X), Whole Match/,
    key: 'playerToScoreGoalXWholeMatch',
  },
  {
    pattern: /Action To Score Goal (\d+|X), Ordinary Time/,
    key: 'actionToScoreGoalXOrdinaryTime',
  },
  {
    pattern: /Action To Score Goal (\d+|X), Overtime Excluding Penalty Round/,
    key: 'actionToScoreGoalXOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /Goal (\d+|X) Before Minute (\d{2}:\d{2}), Ordinary Time/,
    key: 'goalXBeforeMinuteYOrdinaryTime',
  },
  {
    pattern: /Interval Of Goal (\d+|X), Ordinary Time/,
    key: 'intervalOfGoalXOrdinaryTime',
  },
  {
    pattern: /Interval Of Team Goal (\d+|X), Ordinary Time/,
    key: 'intervalOfTeamGoalXOrdinaryTime',
  },
  {
    pattern: /Matchbet & Team To Score Goal (\d+|X), Ordinary Time/,
    key: 'matchbetAndTeamToScoreGoalXOrdinaryTime',
  },
  {
    pattern: /Matchbet & Time Of Goal (\d+|X), Ordinary Time/,
    key: 'matchbetAndTimeOfGoalXOrdinaryTime',
  },
  {
    pattern: /Method of Goal (\d+|X), 1st Half \(Ordinary Time\)/,
    key: 'methodOfGoalX1stHalfOrdinaryTime',
  },
  {
    pattern: /Method of Goal (\d+|X), 1st Half \(Overtime\)/,
    key: 'methodOfGoalX1stHalfOvertime',
  },
  {
    pattern: /Method of Goal (\d+|X), 2nd Half \(Ordinary Time\)/,
    key: 'methodOfGoalX2ndHalfOrdinaryTime',
  },
  {
    pattern: /Method of Goal (\d+|X), 2nd Half \(Overtime\)/,
    key: 'methodOfGoalX2ndHalfOvertime',
  },
  {
    pattern: /Method of Goal (\d+|X), Ordinary Time/,
    key: 'methodOfGoalXOrdinaryTime',
  },
  {
    pattern: /Method of Goal (\d+|X), Overtime Excluding Penalty Round/,
    key: 'methodOfGoalXOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /Team Goal (\d+|X) Before Minute (\d{2}:\d{2}), Ordinary Time/,
    key: 'teamGoalXBeforeMinuteYOrdinaryTime',
  },
];

export const intervalPatterns = [
  {
    pattern: /^Goals Over\/Under (\d+\.\d+) In Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'goalsOverUnderInInterval',
  },
  {
    pattern: /^Both Teams To Score In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'bothTeamsToScoreInInterval',
  },
  {
    pattern: /^Double Chance In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'doubleChanceInInterval',
  },
  {
    pattern: /^Corner (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'cornerXInInterval',
  },
  {
    pattern: /^Cards Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'cardsOverUnderInInterval',
  },
  {
    pattern: /^Corners Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'cornersOverUnderInInterval',
  },
  {
    pattern: /^Free Kicks Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'freeKicksOverUnderInInterval',
  },
  {
    pattern: /^Team Corners Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'teamCornersOverUnderInInterval',
  },
  {
    pattern: /^Team Goals Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'teamGoalsOverUnderInInterval',
  },
  {
    pattern: /^Throw-Ins Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'throwInsOverUnderInInterval',
  },
  {
    pattern: /^Goal Kicks Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'goalKicksOverUnderInInterval',
  },
  {
    pattern: /^Goals Over\/Under (\d+) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'goalsOverUnderInInterval',
  },
  {
    pattern: /^Which Player Will Score A Goal In Interval\?, (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'whichPlayerWillScoreInInterval',
  },
  {
    pattern: /^Interval Of Card (\d+)?, (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'intervalOfCard',
  },
  {
    pattern: /^Interval Of Corner (\d+)?, (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'intervalOfCorner',
  },
  {
    pattern: /^Interval Of Goal (\d+)?, (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'intervalOfGoal',
  },
  {
    pattern: /^Interval Of Team Goal (\d+)?, (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'intervalOfTeamGoal',
  },
  {
    pattern: /^Home Draw Away In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'homeDrawAwayInInterval',
  },
  {
    pattern: /^Most Corners In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'mostCornersInInterval',
  },
  {
    pattern: /^Most Corners \(2-Way\) In Interval(\s*,\s*)? (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)$/,
    key: 'mostCorners2WayInInterval',
  },
  {
    pattern: /^Will A Card Be Shown In Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)\?$/,
    key: 'willACardBeShownInInterval',
  },
  {
    pattern: /^Will A Corner Kick Be Awarded In Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)\?$/,
    key: 'willACornerKickBeAwardedInInterval',
  },
  {
    pattern: /^Will A Corner Kick Be Awarded To Team In Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)\?$/,
    key: 'willACornerKickBeAwardedToTeamInInterval',
  },
  {
    pattern: /^Will A Goal Be Scored By Team In Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)\?$/,
    key: 'willAGoalBeScoredByTeamInInterval',
  },
  {
    pattern: /^Will A Goal Be Scored In Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)\?$/,
    key: 'willAGoalBeScoredInInterval',
  },
  {
    pattern: /^Will Corner (\d+) Be Awarded In Interval (\d{2}:\d{2}-\d{2}(?::\d{2})?\+?)\?$/,
    key: 'willCornerXBeAwardedInInterval',
  },
];

export const scorePatterns = [
  {
    pattern: /Action To Score (\d+|X) With Money Back On No Goal, Ordinary Time/,
    key: 'actionToScoreXWithMoneyBackOnNoGoalOrdinaryTime',
  },
  {
    pattern: /To Score (\d+|X) Or More Goals, Ordinary Time/,
    key: 'toScoreXOrMoreGoalsOrdinaryTime',
  },
  {
    pattern: /To Score (\d+|X) Or More Goals, Overtime Excluding Penalty Round/,
    key: 'toScoreXOrMoreGoalsOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /To Score (\d+|X) Or More Goals, Whole Match/,
    key: 'toScoreXOrMoreGoalsWholeMatch',
  },
  {
    pattern: /Next Field Goal Method 2-Way \(After Score (\d{1,2}-\d{1,2})\), Whole Match/,
    key: 'nextFieldGoalMethod2WayAfterScoreXWholeMatch',
  },
  {
    pattern: /Next Field Goal Method 4-Way \(After Score (\d{1,2}-\d{1,2})\), Whole Match/,
    key: 'nextFieldGoalMethod4WayAfterScoreXWholeMatch',
  },
  {
    pattern: /Both Teams to Score or Over (X|\d+) Goals in Match, 1st Half \(Ordinary Time\)/,
    key: 'bothTeamsToScoreOrOverXGoalsInMatch1stHalfOrdinaryTime',
  },
  {
    pattern: /Both Teams to Score or Over (X|\d+) Goals in Match, 2nd Half \(Ordinary Time\)/,
    key: 'bothTeamsToScoreOrOverXGoalsInMatch2ndHalfOrdinaryTime',
  },
  {
    pattern: /Both Teams to Score or Over (X|\d+) Goals in Match, Ordinary Time/,
    key: 'bothTeamsToScoreOrOverXGoalsInMatchOrdinaryTime',
  },
  {
    pattern: /Asian Handicap With Score \{(\d+:\d+)\}, 1st Half \(Ordinary Time\)/,
    key: 'asianHandicapWithScore1stHalfOrdinaryTime',
  },
  {
    pattern: /Asian Handicap With Score \{(\d+:\d+)\}, 1st Half \(Overtime\)/,
    key: 'asianHandicapWithScore1stHalfOvertime',
  },
  {
    pattern: /Asian Handicap With Score \{(\d+:\d+)\}, 2nd Half \(Ordinary Time\)/,
    key: 'asianHandicapWithScore2ndHalfOrdinaryTime',
  },
  {
    pattern: /Asian Handicap With Score \{(\d+:\d+)\}, 2nd Half \(Overtime\)/,
    key: 'asianHandicapWithScore2ndHalfOvertime',
  },
  {
    pattern: /Asian Handicap With Score \{(\d+:\d+)\}, Ordinary Time/,
    key: 'asianHandicapWithScoreOrdinaryTime',
  },
  {
    pattern: /Asian Handicap With Score \{(\d+:\d+)\}, Overtime Excluding Penalty Round/,
    key: 'asianHandicapWithScoreOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /Asian Handicap With Score \{(\d+:\d+)\}, Penalty Round/,
    key: 'asianHandicapWithScorePenaltyRound',
  },
];

export const noBetPatterns = [
  {
    pattern: /^(.*?) No Bet, 1st Half \(Ordinary Time\)$/,
    key: 'teamNoBet1stHalfOrdinaryTime',
  },
  {
    pattern: /^(.*?) No Bet, 2nd Half \(Ordinary Time\)$/,
    key: 'teamNoBet2ndHalfOrdinaryTime',
  },
  {
    pattern: /^(.*?) No Bet, Ordinary Time$/,
    key: 'teamNoBetOrdinaryTime',
  },
  {
    pattern: /^(.*?) No Bet, Overtime Excluding Penalty Round$/,
    key: 'teamNoBetOvertimeExcludingPenaltyRound',
  },
  {
    pattern: /^(.*?) No Bet, 1st Period$/,
    key: 'teamNoBet1stPeriod',
  },
  {
    pattern: /^(.*?) No Bet, 2nd Period$/,
    key: 'teamNoBet2ndPeriod',
  },
  {
    pattern: /^(.*?) No Bet, 3rd Period$/,
    key: 'teamNoBet3rdPeriod',
  },
];

export const gamesPatterns = [
  {
    pattern: /^Games (\d+) And (\d+) Winner, 1st Set$/,
    key: 'gamesXAndYWinner1stSet',
  },
  {
    pattern: /^Games (\d+) And (\d+) Winner, 2nd Set$/,
    key: 'gamesXAndYWinner2ndSet',
  },
  {
    pattern: /^Games (\d+) And (\d+) Winner, 3rd Set$/,
    key: 'gamesXAndYWinner3rdSet',
  },
  {
    pattern: /^Games (\d+) And (\d+) Winner, 4th Set$/,
    key: 'gamesXAndYWinner4thSet',
  },
  {
    pattern: /^Games (\d+) And (\d+) Winner, 5th Set$/,
    key: 'gamesXAndYWinner5thSet',
  },
];

export const racePatterns = [
  {
    pattern: /^Race To (\d+) Games, 1st Set$/,
    key: 'raceToXGames1stSet',
  },
  {
    pattern: /^Race To (\d+) Games, 2nd Set$/,
    key: 'raceToXGames2ndSet',
  },
  {
    pattern: /^Race To (\d+) Games, 3rd Set$/,
    key: 'raceToXGames3rdSet',
  },
  {
    pattern: /^Race To (\d+) Games, 4th Set$/,
    key: 'raceToXGames4thSet',
  },
  {
    pattern: /^Race To (\d+) Games, 5th Set$/,
    key: 'raceToXGames5thSet',
  },
  {
    pattern: /^Race To (\d+) Games, Whole Match$/,
    key: 'raceToXGamesWholeMatch',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, (\d+)(?:st|nd|rd|th) Game \(1st Set\)$/,
    key: 'raceToXPoints2WayXGame1stSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, (\d+)(?:st|nd|rd|th) Game \(2nd Set\)$/,
    key: 'raceToXPoints2WayXGame2ndSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, (\d+)(?:st|nd|rd|th) Game \(3rd Set\)$/,
    key: 'raceToXPoints2WayXGame3rdSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, (\d+)(?:st|nd|rd|th) Game \(4th Set\)$/,
    key: 'raceToXPoints2WayXGame4thSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, (\d+)(?:st|nd|rd|th) Game \(5th Set\)$/,
    key: 'raceToXPoints2WayXGame5thSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, 1st Set$/,
    key: 'raceToXPoints2Way1stSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, 2nd Set$/,
    key: 'raceToXPoints2Way2ndSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, 3rd Set$/,
    key: 'raceToXPoints2Way3rdSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, 4th Set$/,
    key: 'raceToXPoints2Way4thSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, 5th Set$/,
    key: 'raceToXPoints2Way5thSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, Tie-Break \(1st Set\)$/,
    key: 'raceToXPoints2WayTieBreak1stSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, Tie-Break \(2nd Set\)$/,
    key: 'raceToXPoints2WayTieBreak2ndSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, Tie-Break \(3rd Set\)$/,
    key: 'raceToXPoints2WayTieBreak3rdSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, Tie-Break \(4th Set\)$/,
    key: 'raceToXPoints2WayTieBreak4thSet',
  },
  {
    pattern: /^Race To (\d+) Points 2-Way, Tie-Break \(5th Set\)$/,
    key: 'raceToXPoints2WayTieBreak5thSet',
  },
  {
    pattern: /^Race To (\d+) Points, (\d+)(?:st|nd|rd|th) Game \(1st Set\)$/,
    key: 'raceToXPoints10thGame1stSet',
  },
  {
    pattern: /^Race To (\d+) Points, (\d+)(?:st|nd|rd|th) Game \(2nd Set\)$/,
    key: 'raceToXPoints10thGame2ndSet',
  },
  {
    pattern: /^Race To (\d+) Points, (\d+)(?:st|nd|rd|th) Game \(3rd Set\)$/,
    key: 'raceToXPoints10thGame3rdSet',
  },
  {
    pattern: /^Race To (\d+) Points, (\d+)(?:st|nd|rd|th) Game \(4th Set\)$/,
    key: 'raceToXPoints10thGame4thSet',
  },
  {
    pattern: /^Race To (\d+) Points, (\d+)(?:st|nd|rd|th) Game \(5th Set\)$/,
    key: 'raceToXPoints10thGame5thSet',
  },
  {
    pattern: /^Race To (\d+) Points, 1st Set$/,
    key: 'raceToXPoints1stSet',
  },
  {
    pattern: /^Race To (\d+) Points, 2nd Set$/,
    key: 'raceToXPoints2ndSet',
  },
  {
    pattern: /^Race To (\d+) Points, 3rd Set$/,
    key: 'raceToXPoints3rdSet',
  },
  {
    pattern: /^Race To (\d+) Points, 4th Set$/,
    key: 'raceToXPoints4thSet',
  },
  {
    pattern: /^Race To (\d+) Points, 5th Set$/,
    key: 'raceToXPoints5thSet',
  },
  {
    pattern: /^Race To (\d+) Points, Tie-Break \(1st Set\)$/,
    key: 'raceToXPointsTieBreak1stSet',
  },
  {
    pattern: /^Race To (\d+) Points, Tie-Break \(2nd Set\)$/,
    key: 'raceToXPointsTieBreak2ndSet',
  },
  {
    pattern: /^Race To (\d+) Points, Tie-Break \(3rd Set\)$/,
    key: 'raceToXPointsTieBreak3rdSet',
  },
  {
    pattern: /^Race To (\d+) Points, Tie-Break \(4th Set\)$/,
    key: 'raceToXPointsTieBreak4thSet',
  },
  {
    pattern: /^Race To (\d+) Points, Tie-Break \(5th Set\)$/,
    key: 'raceToXPointsTieBreak5thSet',
  },
];

export const firstPatterns = [
  {
    pattern: /^First 2 Points Of Game (\d+) Of Set (\d+), (\d+)(?:st|nd|rd|th) Game \(1st Set\)$/,
    key: 'first2PointsOfGameXOfSetY1stSet',
  },
  {
    pattern: /^First 2 Points Of Game (\d+) Of Set (\d+), (\d+)(?:st|nd|rd|th) Game \(2nd Set\)$/,
    key: 'first2PointsOfGameXOfSetY2ndSet',
  },
  {
    pattern: /^First 2 Points Of Game (\d+) Of Set (\d+), (\d+)(?:st|nd|rd|th) Game \(3rd Set\)$/,
    key: 'first2PointsOfGameXOfSetY3rdSet',
  },
  {
    pattern: /^First 2 Points Of Game (\d+) Of Set (\d+), (\d+)(?:st|nd|rd|th) Game \(4th Set\)$/,
    key: 'first2PointsOfGameXOfSetY4thSet',
  },
  {
    pattern: /^First 2 Points Of Game (\d+) Of Set (\d+), (\d+)(?:st|nd|rd|th) Game \(5th Set\)$/,
    key: 'first2PointsOfGameXOfSetY5thSet',
  },
  {
    pattern: /^(.+) First Service Game$/,
    key: 'firstServiceGame',
  },
];

import { ThemeProvider as MuiThemeProvider, useTheme } from '@mui/material/styles';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import WebFont from 'webfontloader';
import { DEFAULT_THEME } from '../constants/marketplace';
import { createCustomTheme } from '../styles/theme';
import { ThemeOptions, themes } from '../styles/themeConfig';
import { useMarketplaceConfig } from '../queries/marketplace';
import { Backdrop, CircularProgress } from '@mui/material';

type ThemeContextType = {
  selectedTheme: ThemeOptions;
  setTheme: (themeName: ThemeOptions) => void;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  const theme = useTheme();

  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }

  return {
    ...context,
    theme,
  };
};

export const CustomThemeProvider = ({ children }: PropsWithChildren) => {
  const { data: marketplaceConfig, isLoading } = useMarketplaceConfig();

  const [selectedTheme, setSelectedTheme] = useState<ThemeOptions | null>(null);

  useEffect(() => {
    if (marketplaceConfig) {
      setSelectedTheme(marketplaceConfig.branding || DEFAULT_THEME);
    } else if (!isLoading) {
      setSelectedTheme(DEFAULT_THEME);
    }
  }, [marketplaceConfig, isLoading]);

  const themeConfig = selectedTheme ? themes[selectedTheme] : undefined;

  useEffect(() => {
    if (themeConfig) {
      const { typography } = themeConfig;

      WebFont.load({
        google: {
          families: [
            `${typography.h1.fontFamily}:300,400,500,600,700`,
            `${typography.body1.fontFamily}:300,400,500,600,700`,
          ],
        },
      });
    }
  }, [themeConfig]);

  const theme = useMemo(() => (selectedTheme ? createCustomTheme(selectedTheme) : null), [selectedTheme]);

  if (isLoading || !selectedTheme || !theme) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <ThemeContext.Provider value={{ selectedTheme, setTheme: setSelectedTheme }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

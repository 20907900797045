import { Box } from '@mui/material';
import { SanityTopItem, StyleObj } from '../../@types';
import { Link } from 'react-router-dom';
import { getSanityImage } from '../../helpers/sanity';

const styles: StyleObj = {
  container: {
    cursor: 'pointer',
    marginBottom: 0,
    width: '100%',
    flex: '1 0 auto',
  },
};

type CMSCarouselCasinoItemProps = {
  item: SanityTopItem;
};

const CMSCarouselCasinoItem = ({ item }: CMSCarouselCasinoItemProps) => {
  const src = getSanityImage(item.image);

  return (
    <Box sx={styles.container} key={item.title}>
      <Link to={item?.callToActionURL ?? '/'} style={{ textDecoration: 'unset', color: 'unset' }}>
        <img src={src} alt={item.title} style={{ width: '100%', height: '100%' }} />
      </Link>
    </Box>
  );
};

export default CMSCarouselCasinoItem;

import { ExpandMore } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { has, omit } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  calculateMaxPayout,
  calculateMaxStakeAmountForNormalBettingSlip,
  calculateTotalOddsForNormalBettingSlip,
} from 'sportsbook-shared-module';
import {
  AuthorizationResponse,
  BankerMenuOptions,
  Bet,
  Betslip,
  BetslipTicketType,
  BettingSlipAcceptStatus,
  BettingType,
  StakeErrors,
  StakeErrorType,
  StyleObj,
  WalletTypeOptions,
} from '../../@types';
import {
  BANKER_MENU_OPTIONS,
  BETSLIP_WIDTH,
  BETTING_TYPE_HIT_COUNT_MAP,
  CURRENCY,
  QUERY_KEYS,
  WALLET_TYPE_OPTIONS,
} from '../../constants';
import { useBetslip } from '../../contexts/BetslipContext';
import { calculateRoundedMinStakeAmount, getErrorMessageType, mapStakeToCondition } from '../../helpers';
import { useBetslipCombinations } from '../../hooks/useBetslipCombinations';
import { useExtendedSnackbar } from '../../hooks/useExtendedSnackbar';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useLocalization from '../../hooks/useLocalization';
import { useBalances, useGlobalTicketConditions } from '../../queries';
import { usePendingBetslipTracker } from '../../hooks/usePendingBetslipTracker';
import { postData } from '../../utils/api';
import BetslipBetCount from '../atoms/BetslipBetCount';
import BetslipErrorMessage from '../atoms/BetslipErrorMessage';
import BetslipInfoMessage from '../atoms/BetslipInfoMessage';
import BetslipInput from '../atoms/BetslipInput';
import { TRIGGER_MAINTENANCE_MODE_EVENT } from '../layouts/MarketplaceLayout';
import OpenBetslipButton from '../molecules/OpenBetslipButton';
import BetslipMenu from '../molecules/menus/BetslipMenu';
import BetslipItem from './BetslipItem';
import BetslipPlaceBetButton from './BetslipPlaceBetButton';
import PendingTicketOverlay from './PendingTicketOverlay';

const styles: StyleObj = {
  drawer: {
    textAlign: 'center',
    '& .MuiPaper-root': {
      width: {
        xs: '100%',
        sm: BETSLIP_WIDTH,
      },
      background: (theme) => theme.palette.neutral[50],
      borderRadius: {
        xs: 0,
        sm: '4px 4px 0 0',
      },
      margin: 'auto',
      mb: {
        xs: 0,
        md: 4,
      },
    },
    '& form': { maxHeight: '80vh', display: 'flex', flexDirection: 'column' },
  },
  closeButton: {
    borderRadius: 0,
    borderLeft: (theme) => `1px solid ${theme.palette.neutral[100]}`,
    p: 1.5,
  },
  betStack: {
    overflowY: 'auto',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      width: 14,
      borderColor: 'neutral.50',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      backgroundColor: 'neutral.300',
    },
  },
  removeAllButton: { minHeight: 0, p: 0, pl: 1.5, ':hover': { background: 'none' } },
  systemIcon: {
    cursor: 'pointer',
    fontSize: 16,
    color: 'neutral.600',
  },
};

type StakeAmountInput = {
  [key in BettingType]: string;
};

const BetslipDrawer = () => {
  const [open, setOpen] = useState(false);
  const [showSystemBetInputs, setShowSystemBetInputs] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<BankerMenuOptions>('normal');
  const [stakeAmounts, setStakeAmounts] = useState<StakeAmountInput>({} as StakeAmountInput);
  const [stakeErrors, setStakeErrors] = useState<StakeErrors>({});
  const [selectedBalance, setSelectedBalance] = useState<WalletTypeOptions>('main');
  const [disabledSystems, setDisabledSystems] = useState<string[]>([]);

  const { data: globalTicketConditions } = useGlobalTicketConditions();

  const { t, getLocalizedConfig } = useLocalization();

  const localizedBankerMenuOptions = useMemo(() => getLocalizedConfig(BANKER_MENU_OPTIONS), [getLocalizedConfig]);
  const localizedWalletTypeOptions = useMemo(() => getLocalizedConfig(WALLET_TYPE_OPTIONS), [getLocalizedConfig]);

  const { data: balancesData } = useBalances();
  const balance = balancesData?.items.find((balance) => balance.type === selectedBalance)?.balance || 0;
  const {
    bets,
    removeAllBets,
    errors,
    isWaysTicket,
    resetBankers,
    infoMessages,
    currentBankerCount,
    updateSinglesStakeAmount,
    resetSinglesStakeAmounts,
    betslipTicketType,
  } = useBetslip();

  const removeSingleStakeError = useCallback(
    (outcomeId: string) => {
      setStakeErrors((prevState) => omit(prevState, outcomeId));
    },
    [setStakeErrors]
  );

  const { enqueueSnackbar, enqueueWithDelay, closeSnackbar, showTicketAuthorizationCountMessage } =
    useExtendedSnackbar();

  const invalidateData = useInvalidateQuery();

  const closeBetslipDrawer = useCallback(() => {
    setOpen(false);
    setShowSystemBetInputs(false);
  }, []);

  const discardBetslip = useCallback(() => {
    closeBetslipDrawer();
    removeAllBets();
  }, [closeBetslipDrawer, removeAllBets]);

  const { hasPendingBetslips } = usePendingBetslipTracker({
    onAllBetslipsResolved: discardBetslip,
  });

  const placeBetMutation = useMutation({
    mutationFn: (newBetslip: Betslip) => postData('betting-slips', newBetslip),
    onMutate: () => {
      enqueueSnackbar(t('awaitingConfirmation'), {
        variant: 'info',
        persist: true,
        key: 'awaitingConfirmation',
        transitionDuration: 0,
      });
    },
    onSuccess: (data: AuthorizationResponse[]) => {
      closeSnackbar('awaitingConfirmation');
      if (Array.isArray(data)) {
        const MESSAGE_DELAY = 3000;

        const rejectedMessages: string[] = [];
        const statusCounts = data.reduce(
          (counts, { acceptStatus, detailedResults }) => {
            counts[acceptStatus] = (counts[acceptStatus] || 0) + 1;

            if (acceptStatus === 'rejected') {
              const message = Array.from(
                new Set(detailedResults.map((result) => result.clientMessage).filter(Boolean))
              ).join('\n'); // Filter out duplicate messages

              rejectedMessages.push(message || t('bettingSlipRejected'));
            }
            return counts;
          },
          { pending: 0, rejected: 0, accepted: 0 } as Record<Partial<BettingSlipAcceptStatus>, number>
        );

        let cumulativeDelay = 0;

        if (statusCounts.pending > 0) {
          showTicketAuthorizationCountMessage('pending', statusCounts.pending, cumulativeDelay);
          cumulativeDelay += MESSAGE_DELAY;
        }

        rejectedMessages.forEach((message, index) => {
          enqueueWithDelay(
            message,
            { variant: 'warning', style: { whiteSpace: 'pre-line' }, preventDuplicate: false },
            cumulativeDelay + MESSAGE_DELAY * index
          );
        });

        cumulativeDelay += statusCounts.rejected * MESSAGE_DELAY;

        if (statusCounts.accepted > 0) {
          showTicketAuthorizationCountMessage('accepted', statusCounts.accepted, cumulativeDelay);
        }

        if (!statusCounts.pending) {
          discardBetslip();
        }

        invalidateData([
          QUERY_KEYS.myBetsCount,
          QUERY_KEYS.myBets,
          QUERY_KEYS.balance,
          QUERY_KEYS.jackpots,
          QUERY_KEYS.pendingBetslipsCount,
        ]);

        setStakeAmounts({} as StakeAmountInput);
      }
    },

    onError: (error) => {
      closeSnackbar('awaitingConfirmation');
      if (error instanceof AxiosError) {
        const httpCode = error.response?.status;

        if (httpCode === 503) {
          window.dispatchEvent(new CustomEvent(TRIGGER_MAINTENANCE_MODE_EVENT));
        } else {
          enqueueSnackbar(
            error.response?.data?.errorMessage?.message ||
              error.response?.data?.errorMessage ||
              'Internal Server Error',
            { variant: 'error' }
          );
        }
      }
    },
  });

  const updateStakeAmount = (key: string, value: string) => {
    setStakeAmounts((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const resetSystemStakeAmountValues = useCallback(() => {
    setStakeAmounts((prevState) => {
      const updatedStakeAmounts = Object.keys(prevState).reduce((acc, key) => {
        removeSingleStakeError(key);
        return { ...acc, [key]: '' };
      }, {} as StakeAmountInput);
      return updatedStakeAmounts;
    });
  }, [setStakeAmounts, removeSingleStakeError]);

  const betTypes = useMemo(
    () =>
      Object.entries(stakeAmounts)
        .map(([bettingType, stakeAmount]) => ({
          requiredHitCount: BETTING_TYPE_HIT_COUNT_MAP[bettingType as BettingType] - currentBankerCount,
          stakeAmountPerCombination: Number(stakeAmount),
        }))
        .filter((betType) => betType.stakeAmountPerCombination > 0 && betType.requiredHitCount > 0),
    [stakeAmounts, currentBankerCount]
  );

  const preparedBets = useMemo(
    () =>
      bets.map((bet) => {
        return {
          ...bet,
          marketTypeId: bet.marketType.id,
          marketTypeCombiningIds: bet.marketType.marketTypeCombiningIds,
          singlesStakeAmount: bet.disabled ? 0 : Number(bet.singlesStakeAmount),
          specialValues: bet.specialValues,
        };
      }),
    [bets]
  );

  const { maxTicketStake: globalMaxTicketStake, maxWinning: globalMaxWinning } =
    globalTicketConditions?.[betslipTicketType] ?? {};
  const { maxPayout, totalStakeAmount } = calculateMaxPayout(
    {
      bets: preparedBets,
      betTypes,
    },
    globalMaxWinning ?? Infinity,
    globalMaxTicketStake ?? Infinity
  );

  const drawerOpen = open || bets.length === 0;
  const totalOdds = useMemo(() => calculateTotalOddsForNormalBettingSlip(preparedBets), [preparedBets]);

  const hasEnoughBalance = balance >= totalStakeAmount;
  const hasDisabledBets = bets.some((bet) => bet.disabled);
  const isPlaceBetButtonDisabled =
    !totalStakeAmount || !hasEnoughBalance || hasDisabledBets || placeBetMutation.isLoading;

  const combinations = useBetslipCombinations(preparedBets, currentBankerCount, showSystemBetInputs, drawerOpen);

  const lastCombinationIndex = useMemo(() => Object.entries(combinations).length - 1, [combinations]);
  const [lastCombinationKey, lastCombinationValue] = useMemo(
    () => Object.entries(combinations)[lastCombinationIndex] || [],
    [combinations, lastCombinationIndex]
  );

  const getMaxWinningStake = useCallback(
    (outcomeId: string, isMultipleTicket = false) => {
      const singleBet = bets.find((bet) => bet.outcomeId === outcomeId);
      let ticketType = betslipTicketType;
      let ticketOdds = totalOdds;
      if (singleBet) {
        const { odds, isLive } = singleBet;
        ticketOdds = Number(odds);
        ticketType = isLive ? 'inPlay' : 'preMatch';
      }
      const singleMaxWin = globalTicketConditions?.[ticketType]?.maxWinning ?? Infinity;
      return isMultipleTicket
        ? Infinity
        : calculateMaxStakeAmountForNormalBettingSlip(Number(ticketOdds), singleMaxWin);
    },
    [bets, betslipTicketType, globalTicketConditions, totalOdds]
  );

  const updateStakes = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      Object.entries(stakeErrors).forEach(([key, { errorType }]) => {
        const singleTicketBet = bets.find((bet) => bet.outcomeId === key);
        const singleTicketType = singleTicketBet?.isLive ? 'inPlay' : 'preMatch';
        const ticketType = singleTicketBet ? singleTicketType : betslipTicketType;

        const maxWinningStake = getMaxWinningStake(key);
        const combinationCount = Object.entries(combinations).find(([combinationKey]) => combinationKey === key)?.[1];
        const updatedStake = mapStakeToCondition(
          ticketType,
          errorType,
          maxWinningStake,
          globalTicketConditions,
          combinationCount
        );

        if (!updatedStake) return;

        removeSingleStakeError(key);
        if (has(stakeAmounts, key)) {
          updateStakeAmount(key, updatedStake.toFixed(2).toString());
          return;
        }

        updateSinglesStakeAmount(key, updatedStake.toFixed(2).toString());
      });
    },
    [
      bets,
      betslipTicketType,
      combinations,
      getMaxWinningStake,
      globalTicketConditions,
      removeSingleStakeError,
      stakeAmounts,
      stakeErrors,
      updateSinglesStakeAmount,
    ]
  );

  const updateStakeErrors = useCallback(
    (key: string, value: StakeErrorType) => {
      setStakeErrors((prevState) => {
        return { ...prevState, [key]: { errorType: value } };
      });
    },
    [setStakeErrors]
  );

  const handleStakeChange = (
    key: string,
    value: string,
    callback: (key: string, value: string) => void,
    isSingle = false,
    betslipTicketType?: BetslipTicketType,
    numberOfCombinations = 1
  ) => {
    const isValidValue = (value: string) => {
      return value !== '' && Number(value) !== 0;
    };

    if (!globalTicketConditions || !betslipTicketType) {
      callback(key, value);
      return;
    }

    const stakeAmount = Number(value);
    const { minTicketStake: minStake, maxTicketStake, maxStakeSingle } = globalTicketConditions[betslipTicketType];
    const filteredBets = preparedBets.map((bet) => ({
      ...bet,
      singlesStakeAmount: 0,
    }));

    const betTypes = [
      {
        requiredHitCount: BETTING_TYPE_HIT_COUNT_MAP[key as BettingType] - currentBankerCount,
        stakeAmountPerCombination: Number(stakeAmount),
      },
    ].filter((betType) => betType.stakeAmountPerCombination > 0 && betType.requiredHitCount > 0);

    const { maxPayout } = calculateMaxPayout(
      {
        bets: filteredBets,
        betTypes,
      },
      Infinity,
      Infinity
    );

    const hasSingleMaxStake = isSingle && !!maxStakeSingle;

    const maxStake = (hasSingleMaxStake ? maxStakeSingle : maxTicketStake) ?? Infinity;

    const maxStakePerCombination =
      numberOfCombinations > 1 ? calculateMaxStakeAmountForNormalBettingSlip(numberOfCombinations, maxStake) : maxStake;

    const maxWinningStake = getMaxWinningStake(key, numberOfCombinations > 1);

    const minStakePerCombination =
      numberOfCombinations > 1 ? calculateRoundedMinStakeAmount(numberOfCombinations, minStake ?? 0) : minStake;

    if (disabledSystems.includes(key)) {
      setDisabledSystems((prev) => prev.filter((system) => system !== key));
    }

    if (!isValidValue(value)) {
      setStakeErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[key];
        return updatedErrors;
      });
    } else {
      if (minStakePerCombination && stakeAmount < minStakePerCombination) {
        updateStakeErrors(key, getErrorMessageType(isSingle, betslipTicketType, 'min'));
      } else if (stakeAmount > Math.min(maxWinningStake, maxStakePerCombination)) {
        updateStakeErrors(
          key,
          maxWinningStake < maxStakePerCombination
            ? 'maxWinLimit'
            : getErrorMessageType(hasSingleMaxStake, betslipTicketType, 'max')
        );
      } else if (globalMaxWinning && maxPayout > globalMaxWinning) {
        setDisabledSystems((prev) => [...prev, key]);
        updateStakeErrors(key, 'maxWinLimit');
      } else {
        setStakeErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[key];
          return updatedErrors;
        });
      }
    }
    callback(key, value);
  };

  const preparePayload = useCallback(() => {
    const betsPayload: Bet[] = bets
      .filter((bet) => !bet.disabled)
      .map((bet) => ({
        eventId: bet.eventId,
        outcomeId: bet.outcomeId,
        marketId: bet.marketId,
        odds: bet.odds,
        singlesStakeAmount: Number(bet.singlesStakeAmount || 0) || undefined,
        banker: bet.banker,
      }));

    return {
      bets: betsPayload,
      betTypes,
      walletType: selectedBalance,
      totalStakeAmount,
      currency: CURRENCY.code,
    };
  }, [bets, betTypes, selectedBalance, totalStakeAmount]);

  const placeBet = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload = preparePayload();
    placeBetMutation.mutate(payload);
  };

  useEffect(() => {
    setShowSystemBetInputs(false);
    resetSystemStakeAmountValues();
  }, [bets.length, resetSystemStakeAmountValues]);

  useEffect(() => {
    if (bets.length === 0) {
      setStakeErrors({});
      closeBetslipDrawer();
    }
  }, [bets.length, closeBetslipDrawer]);

  useEffect(() => {
    // Redirect user back to normal betslip since bankers are redundant with less than 3 selections
    if (bets.length < 3 && selectedMenuItem === 'bankers') {
      setSelectedMenuItem('normal');
    }
  }, [bets.length, selectedMenuItem]);

  useEffect(() => {
    // Bankers are not allowed in ways ticket
    if (isWaysTicket) {
      resetBankers();
      setSelectedMenuItem('normal');
    }
  }, [isWaysTicket, resetBankers]);

  return (
    <>
      <OpenBetslipButton open={drawerOpen} setOpen={setOpen} />
      <PendingTicketOverlay open={hasPendingBetslips} />
      <Drawer anchor='bottom' elevation={0} open={open} onClose={closeBetslipDrawer} sx={styles.drawer}>
        <form onSubmit={placeBet}>
          {Array.from(infoMessages)?.map((message) => <BetslipInfoMessage key={message} message={message} />)}
          <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
            <BetslipBetCount numberOfBets={bets.length} pl={3} />
            <Stack alignItems='end' flexGrow={1}>
              <BetslipMenu
                selectedMenuItem={selectedBalance}
                onChange={(value) => {
                  setSelectedBalance(value as WalletTypeOptions);
                }}
                options={localizedWalletTypeOptions}
                style={{ marginRight: -12 }}
              />

              <Typography variant='body2' fontWeight={600} color='neutral.600'>
                {CURRENCY.symbol}
                {balance.toFixed(2)}
              </Typography>
            </Stack>
            <IconButton sx={styles.closeButton} onClick={closeBetslipDrawer}>
              <ExpandMore />
            </IconButton>
          </Stack>
          <Divider />
          <Stack direction='row' justifyContent='space-between' alignItems={'center'}>
            <Button variant='text' disableRipple sx={styles.removeAllButton} onClick={removeAllBets}>
              <Typography variant='body3' color='primary'>
                {t('removeAll')}
              </Typography>
            </Button>
            <BetslipMenu
              selectedMenuItem={selectedMenuItem}
              onChange={(value) => {
                resetSinglesStakeAmounts();
                setStakeAmounts({} as StakeAmountInput);
                resetBankers();
                setSelectedMenuItem(value as BankerMenuOptions);
              }}
              options={localizedBankerMenuOptions}
              disabledOptions={isWaysTicket || bets.length < 3 ? ['bankers'] : []}
            />
          </Stack>
          <Divider />
          <Stack sx={styles.betStack}>
            {bets.map((bet) => (
              <BetslipItem
                key={bet.outcomeId}
                bet={bet}
                selectedMenuItem={selectedMenuItem}
                value={bet.singlesStakeAmount?.toString() || ''}
                onChange={(value) =>
                  handleStakeChange(
                    bet.outcomeId,
                    value,
                    updateSinglesStakeAmount,
                    true,
                    bet.isLive ? 'inPlay' : 'preMatch'
                  )
                }
                onRemoveBet={() => removeSingleStakeError(bet.outcomeId)}
                errorMessages={stakeErrors[bet.outcomeId]}
              />
            ))}
          </Stack>
          <Divider />
          {bets.length > 1 && (
            <Stack direction='row' alignItems='center'>
              <Typography variant='body3' color='neutral.600' pl={1.5} pr={0.25} py={1} textAlign='left'>
                {t('multiple')}/{t('system')}
              </Typography>
              {bets.length > 1 && (
                <ExpandMore
                  onClick={() => {
                    setShowSystemBetInputs((prev) => !prev);
                  }}
                  sx={{ ...styles.systemIcon, ...(showSystemBetInputs && { transform: 'rotate(180deg)' }) }}
                />
              )}
            </Stack>
          )}
          {showSystemBetInputs &&
            Object.entries(combinations).map(
              ([key, combinationValue]) =>
                key !== lastCombinationKey && (
                  <BetslipInput
                    key={key}
                    bettingType={key as BettingType}
                    numberOfCombinations={combinationValue}
                    value={stakeAmounts[key as BettingType]}
                    onChange={(value) =>
                      handleStakeChange(key, value, updateStakeAmount, false, betslipTicketType, combinationValue)
                    }
                    errorMessage={stakeErrors[key]?.errorType}
                  />
                )
            )}
          {bets.length > 1 && (
            <BetslipInput
              bettingType={lastCombinationKey as BettingType}
              odds={!isWaysTicket && selectedMenuItem === 'normal' ? `(${totalOdds.toFixed(2)})` : ''}
              numberOfCombinations={lastCombinationValue}
              value={stakeAmounts[lastCombinationKey as BettingType]}
              onChange={(value) =>
                handleStakeChange(
                  lastCombinationKey as BettingType,
                  value,
                  updateStakeAmount,
                  false,
                  betslipTicketType,
                  lastCombinationValue
                )
              }
              errorMessage={stakeErrors[lastCombinationKey as string]?.errorType}
            />
          )}
          <Divider />

          {balance < totalStakeAmount && <BetslipErrorMessage message={t('insufficientFunds')} />}
          {errors.map((message, index) => (
            <BetslipErrorMessage key={`betslip-error-message-${index}`} message={message} />
          ))}

          <Typography variant='body3' color='neutral.600' px={1.5} py={1} textAlign='right'>
            {t('maxPossibleWinning')}: {CURRENCY.symbol}
            {maxPayout.toFixed(2)}
          </Typography>
          <BetslipPlaceBetButton
            stakeErrors={stakeErrors}
            totalStakeAmount={totalStakeAmount}
            updateStakes={updateStakes}
            isDisabled={isPlaceBetButtonDisabled}
            disabledSystems={disabledSystems}
          />
        </form>
      </Drawer>
    </>
  );
};

export default BetslipDrawer;

import { Box, ClickAwayListener, Divider, Popper, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useState } from 'react';
import { JackpotPool, JackpotStatistics, StyleObj } from '../../@types';
import { CURRENCY, QUERY_KEYS } from '../../constants';
import { getData } from '../../utils/api';
import ArrowIcon from '../icons/ArrowIcon';
import DiamondIcon from '../icons/DiamondIcon';
import useBreakpoints from '../../hooks/useBreakpoints';
import useLocalization from '../../hooks/useLocalization';

const borderStyles = {
  border: '1px solid',
  borderColor: 'info.600',
};

const fireBackground = {
  backgroundImage: 'url(/fire.svg)',
  backgroundColor: 'rgb(0,0,0,0.6)',
  backgroundPosition: 'center',
  backgroundBlendMode: 'overlay',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

const styles: StyleObj = {
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 1,
    ...borderStyles,
    borderRadius: 1,
    cursor: 'pointer',
    px: { xs: 1, md: 2 },
    py: { xs: 0.25, md: 0.75 },
  },
  name: {
    typography: { xs: 'body3', md: 'body2' },
    color: 'neutral.100',
  },
  amount: {
    typography: { xs: 'h5', lg: 'h4' },
    fontWeight: 700,
    minWidth: 'fit-content',
  },
  divider: {
    width: 2,
    height: 24,
    background: (theme) =>
      `linear-gradient(180deg, rgba(254, 218, 43, 0.00) 0%, ${theme.palette.info[600]} 50%, rgba(254, 218, 43, 0.00) 100%)`,
  },
  popperContainer: {
    ...borderStyles,
    borderRadius: 2,
    backgroundColor: 'neutral.800',
    mt: 2,
  },
  popperArrow: {
    position: 'absolute',
    top: 2,
    left: 30,
  },
  popperContent: {
    p: {
      xs: 1,
      md: 2,
    },
    gap: 2,
  },
  popperText: {
    typography: {
      xs: 'body4',
      md: 'body2',
    },
    color: 'neutral.200',
    '> span': {
      typography: {
        xs: 'body4',
        md: 'body2',
      },
    },
  },
  popperHighlightedText: {
    typography: {
      xs: 'body4',
      md: 'body2',
    },
    color: 'info.600',
  },
  diamondContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  diamondAmount: {
    typography: {
      xs: 'h6',
      md: 'h4',
    },
    ml: 1,
  },
  dividerFull: {
    backgroundColor: 'info.600',
  },
  icon: {
    m: { xs: 'auto', md: '0' },
  },
};

type Props = {
  jackpotPool: JackpotPool;
  icon: JSX.Element;
};

const JackpotInfo = ({ jackpotPool, icon }: Props) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);

  const { isMobile } = useBreakpoints();

  const { t } = useLocalization();

  const { data: jackpotStatistics } = useQuery({
    queryKey: [QUERY_KEYS.jackpots, jackpotPool.id],
    queryFn: (): Promise<JackpotStatistics> => getData(`jackpots/pool/${jackpotPool?.jackpotId}`),
    enabled: open,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElement(anchorElement ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <Stack
        direction='row'
        onClick={handleClick}
        sx={{ ...styles.container, ...(jackpotPool.isActive && fireBackground) }}
      >
        <Stack alignItems='center' sx={styles.icon}>
          {icon}
        </Stack>
        {!isMobile && (
          <Typography sx={styles.name} noWrap>
            {jackpotPool.name}
          </Typography>
        )}
        <Stack direction='row' alignItems='center' spacing={1}>
          <Box sx={styles.divider} />
          <Typography sx={styles.amount} noWrap>
            {jackpotPool.amount.toFixed(2)}
            {CURRENCY.symbol}
          </Typography>
        </Stack>
      </Stack>
      <Popper id={jackpotPool.id} open={open} anchorEl={anchorElement} sx={{ zIndex: 10 }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Stack sx={styles.popperContainer} onClick={handleClose}>
            <Box sx={styles.popperArrow}>
              <ArrowIcon />
            </Box>
            {isMobile && (
              <>
                <Typography sx={{ ...styles.popperText, textAlign: 'center', py: 1 }}>{jackpotPool.name}</Typography>
                <Divider sx={styles.dividerFull} />
              </>
            )}
            <Stack sx={styles.popperContent}>
              <Typography sx={styles.popperText}>
                {t('largestWinner')}:{' '}
                {jackpotStatistics?.largestWinner &&
                  dayjs(jackpotStatistics?.largestWinner?.wonAt).format('DD/MM/YYYY')}
              </Typography>
              <Stack sx={styles.diamondContainer}>
                <DiamondIcon />
                <Typography component='span' sx={styles.diamondAmount}>
                  {jackpotStatistics?.largestWinner?.amount?.toFixed(2)}
                  {CURRENCY.symbol}
                </Typography>
              </Stack>
            </Stack>
            <Box sx={styles.popperContent}>
              <Typography sx={styles.popperText}>
                {t('numberOfWinners')}:{' '}
                <Typography component='span' fontWeight={600} color='info.600'>
                  {jackpotStatistics?.totalWinnersCount}
                </Typography>
              </Typography>
            </Box>
            <Divider sx={styles.dividerFull} />
            <Stack sx={styles.popperContent}>
              <Typography sx={styles.popperText}>
                {t('latestWinner')}:{' '}
                {jackpotStatistics?.latestWinner && dayjs(jackpotStatistics?.latestWinner?.wonAt).format('DD/MM/YYYY')}
              </Typography>
              <Typography sx={styles.popperHighlightedText}>{jackpotStatistics?.latestWinner?.winnerName}</Typography>
              <Stack sx={styles.diamondContainer}>
                <DiamondIcon />
                <Typography component='span' sx={styles.diamondAmount}>
                  {jackpotStatistics?.latestWinner?.amount?.toFixed(2)}
                  {CURRENCY.symbol}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default JackpotInfo;

import React, { useCallback, useEffect, useState } from 'react';
import { use } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { useUserSettings } from '../queries';
import { Backdrop } from '@mui/material';
import { LanguageType } from '../@types';
import { localizeDayjs } from './dayjs';

interface Locale {
  code: LanguageType;
  label: string;
  englishLabel?: string;
  shortLabel?: string;
}

//locales should be received from CRM configuration once multitenancy is completed
const locales: Locale[] = [
  {
    code: 'en',
    label: 'English (US)',
    shortLabel: 'EN',
  },
  // {
  //   code: 'sr',
  //   label: 'Srpski',
  //   englishLabel: 'Serbian',
  //   shortLabel: 'SR',
  // },
  {
    code: 'sq',
    label: 'Shqip',
    englishLabel: 'Albanian',
    shortLabel: 'SQ',
  },
];

const sourcePath = '/locales/{{lng}}/{{ns}}.json'; // Translations are located in public file until we complete all translations
const defaultLng = 'en';

const setUp = async (lng: string) => {
  use(Backend)
    .use(initReactI18next)
    .init(
      {
        fallbackLng: defaultLng,
        load: 'currentOnly',
        ns: ['common', 'sports', 'outcomes', 'eventParts', 'markets'],
        defaultNS: 'common',
        lng,
        debug: false,
        backend: {
          loadPath: sourcePath,
        },
        returnNull: true, // Add this line to return null if key is not found
      },
      (err) => {
        /*eslint-disable-next-line no-console*/
        if (err) return console.error('something went wrong loading', err);
      }
    );
};

interface Props {
  children: React.ReactElement;
}

export const LanguageInstanceProvider = ({ children }: Props): React.ReactElement => {
  const { data: userSettings, isLoading: isLoadingSettings } = useUserSettings();
  const [isInitialized, setIsInitialized] = useState(false);

  const getDefaultLanguage = useCallback(() => {
    const sourceLng = userSettings?.language;
    const matchedLng = locales?.find((lng) => lng.code.toLowerCase() === sourceLng?.toLowerCase());
    return matchedLng?.code ?? sourceLng ?? defaultLng;
  }, [userSettings?.language]);

  useEffect(() => {
    const initLanguage = async () => {
      if (!isLoadingSettings) {
        const defaultLanguage = getDefaultLanguage();
        await setUp(defaultLanguage);
        localizeDayjs(defaultLanguage);
        setIsInitialized(true);
      }
    };

    initLanguage();
  }, [isLoadingSettings, getDefaultLanguage]);

  if (!isInitialized || isLoadingSettings) {
    return <Backdrop open />;
  }

  return children;
};

/* eslint-disable no-console */
import { useState, useEffect, useCallback, useRef } from 'react';
import { SECOND } from '../constants';
import { throttle } from 'lodash-es';

const DEFAULT_THROTTLE_TIME = 3 * SECOND;

// Typed so it behaves similar to useState and can be swapped easily
// Passing { immediate: false } allows throttling updates that happen frequently (eg. socket messages)
type UseLocalStorageReturn<T> = [T, (value: T | ((val: T) => T), options?: { immediate: boolean }) => void, () => void];

const getStoredInitialValue = <T>(key: string, initialValue: T | (() => T)): T => {
  try {
    const item = localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : initialValue instanceof Function ? initialValue() : initialValue;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error);
    return initialValue instanceof Function ? initialValue() : initialValue;
  }
};

const useLocalStorage = <T>(
  key: string,
  initialValue: T | (() => T),
  throttleTime = DEFAULT_THROTTLE_TIME
): UseLocalStorageReturn<T> => {
  const [storedValue, setStoredValue] = useState<T>(() => getStoredInitialValue(key, initialValue));

  const pendingStorageValue = useRef<T | null>(null);

  const writeToLocalStorage = useCallback(
    (value: T) => {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.warn(`Error setting localStorage key "${key}":`, error);
      }
    },
    [key]
  );

  const throttledPersist = useRef(
    throttle(() => {
      if (pendingStorageValue.current) {
        writeToLocalStorage(pendingStorageValue.current);
        pendingStorageValue.current = null;
      }
    }, throttleTime)
  );

  const setValue = useCallback(
    (value: T | ((val: T) => T), options: { immediate?: boolean } = { immediate: true }) => {
      setStoredValue((currentStoredValue) => {
        const newValue = value instanceof Function ? value(currentStoredValue) : value;

        if (options.immediate) {
          writeToLocalStorage(newValue);
        } else {
          pendingStorageValue.current = newValue;
          throttledPersist.current();
        }

        return newValue;
      });
    },
    [throttledPersist, writeToLocalStorage]
  );

  const removeValue = useCallback(() => {
    try {
      localStorage.removeItem(key);
      setStoredValue(initialValue instanceof Function ? initialValue() : initialValue);
    } catch (error) {
      console.warn(`Error removing localStorage key “${key}”:`, error);
    }
  }, [initialValue, key]);

  useEffect(() => {
    const currentThrottledPersist = throttledPersist;

    return () => currentThrottledPersist.current.cancel();
  }, [throttledPersist]);

  return [storedValue, setValue, removeValue];
};

export default useLocalStorage;

import { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';
import { getKeycloakUrl, getKeycloakClientId } from '../utils/urlUtils';

export const KEYCLOAK_CONFIG: KeycloakConfig = {
  url: getKeycloakUrl(),
  clientId: getKeycloakClientId(),
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
};

export const KEYCLOAK_INIT_OPTIONS: KeycloakInitOptions = {
  onLoad: 'login-required',
};
